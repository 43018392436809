<h2>Interviewers</h2>
<div class="interviewer-list">
    <ul ngbNav [(activeId)]="activeId" class="nav-pills" [destroyOnHide]="false" #nav="ngbNav">
        <li [ngbNavItem]="1">
            <a ngbNavLink>Active</a>
            <ng-template ngbNavContent>
                <div class="table-responsive">
                <table *ngIf="interviewers" class="table table-striped table-fixed">
                    <thead>
                        <tr>
                            <th scope="col" class="col-1"></th>
                            <th scope="col" class="col-3" sortable="firstName" (sort)="onActiveSort($event)">First Name</th>
                            <th scope="col" class="col-3" sortable="lastName" (sort)="onActiveSort($event)">Last Name</th>
                            <th scope="col" class="col-3" sortable="email" (sort)="onActiveSort($event)">Email</th>
                            <th scope="col" class="col-1 active-col">Active</th>
                            <th scope="col" class="col-1 sort app-limit-col" sortable="applicantLimit" (sort)="onActiveSort($event)">App Limit</th>
                            <th scope="col" class="col-1 sort promoted-col" sortable="isPromoted" (sort)="onActiveSort($event)"><i
                                    class="fa-solid fa-star"></i></th>
                        </tr>
                    </thead>
                    <tbody class="table-list">
                        <tr *ngFor="let a of activeInterviewers; index as i" (click)="viewInterviewer(a.interviewerId)">
                            <td class="col-1"><app-round-image [url]="getProfileUrl(a.profileUrl)"></app-round-image></td>
                            <td class="col-3">{{ a.firstName }}</td>
                            <td class="col-3">{{ a.lastName }}</td>
                            <td class="col-3">{{ a.email }}</td>
                            <td class="col-1 active-col"><i *ngIf="a.activeForWeb" class="active fa-solid fa-circle-check"></i><i *ngIf="!a.activeForWeb" class="inactive fa-solid fa-circle-xmark"></i></td>
                            <td class="col-1 app-limit-col">{{ a.applicantLimit }}</td>
                            <td class="col-1">
                                <button class="bookmark" *ngIf="a.isPromoted" id="promoted" type="button" [class.btn-danger]="true">
                                    <i class="fa-solid fa-star"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>   
            </div>             
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink>Inactive</a>
            <ng-template ngbNavContent>
                <div class="table-responsive">
                    <table *ngIf="interviewers" class="table table-striped table-fixed">
                        <thead>
                            <tr>
                                <th scope="col" class="col-1"></th>
                                <th scope="col" class="col-3" sortable="firstName" (sort)="onInactiveSort($event)">First Name</th>
                                <th scope="col" class="col-3" sortable="lastName" (sort)="onInactiveSort($event)">Last Name</th>
                                <th scope="col" class="col-3" sortable="email" (sort)="onInactiveSort($event)">Email</th>
                                <th scope="col" class="col-1 active-col">Active</th>
                                <th scope="col" class="col-1 sort app-limit-col" sortable="applicantLimit" (sort)="onInactiveSort($event)">
                                    App Limit</th>
                                <th scope="col" class="col-1 sort promoted-col" sortable="isPromoted" (sort)="onInactiveSort($event)"><i
                                        class="fa-solid fa-star"></i></th>
                            </tr>
                        </thead>
                        <tbody class="table-list">
                            <tr *ngFor="let a of inactiveInterviewers; index as i" (click)="viewInterviewer(a.interviewerId)">
                                <td class="col-1"><app-round-image [url]="getProfileUrl(a.profileUrl)"></app-round-image></td>
                                <td class="col-3">{{ a.firstName }}</td>
                                <td class="col-3">{{ a.lastName }}</td>
                                <td class="col-3">{{ a.email }}</td>
                                <td class="col-1 active-col"><i *ngIf="a.activeForWeb"
                                        class="active fa-solid fa-circle-check"></i><i *ngIf="!a.activeForWeb"
                                        class="inactive fa-solid fa-circle-xmark"></i></td>
                                <td class="col-1 app-limit-col">{{ a.applicantLimit }}</td>
                                <td class="col-1">
                                    <button class="bookmark" *ngIf="a.isPromoted" id="promoted" type="button"
                                        [class.btn-danger]="true">
                                        <i class="fa-solid fa-star"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </li>
    </ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>