<div class="interviewer-details-sidebar">
    <div *ngIf="interviewer">
        <app-interviewer-profile-image [interviewer]="interviewer"></app-interviewer-profile-image>
        <div class="sidebar-card">
            <div class="key-data">
                <span *ngIf="emailAddress" class="d"><i class="one-wide far fa-envelope"></i> <span class="email"> {{emailAddress}}</span></span>
                <span *ngIf="phone" class="d phone"><i class="one-wide fas fa-mobile-alt"></i> {{phone}}</span>
            </div>
        </div>
    </div>
</div>