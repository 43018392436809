<div class="this-week">
    <table class="table table-striped table-fixed forthcoming">
        <thead *ngIf="showInterviewer" class="sortable-header">
            <tr>
                <th [ngClass]="sortType('name')" class="appname" sortableuci="name" (sort)="onSort($event)">Name</th>
                <th [ngClass]="sortType('interviewer')" sortableuci="interviewer" (sort)="onSort($event)">Interviewer</th>
                <th [ngClass]="sortType('interviewDateTime')" class="date" sortableuci="interviewDateTime" (sort)="onSort($event)">Date</th>
                <th class="time">Time</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let w of sortedFilteredList" [class.compact]="compact" (click)="openApplicant(w)">
                <td class="appname">
                    <app-round-image class="pimg" [url]="w.profile" [width]="32" [height]="32"></app-round-image>
                    <span *ngIf="w.status !== 'REJD'">
                        <span *ngIf="filter === '' && !w.isConfirmed" container="body" ngbPopover="Interview not confirmed" popoverClass="slot-popover"
                            triggers="mouseenter:mouseleave" popoverTitle="Note">
                            <i class="warning fa-solid fa-circle-exclamation"></i></span>
                        <!--<span *ngIf="filter === '' && w.isLate" container="body" ngbPopover="Interview is in the past"-->
                        <span *ngIf="w.isLate" container="body" ngbPopover="Interview is in the past"
                            popoverClass="slot-popover" triggers="mouseenter:mouseleave" popoverTitle="Note">
                            <i class="warning-late fa-regular fa-calendar-xmark"></i></span>
                        {{w.name}}
                    </span>
                    <span *ngIf="w.status === 'REJD' && !w.onlineInterviewsAllowed">
                        <span container="body" ngbPopover="Interview is rejected"
                            popoverClass="slot-popover" triggers="mouseenter:mouseleave" popoverTitle="Note">
                            <i class="warning fa-solid fa-circle-xmark"></i></span>
                        {{w.name}}
                    </span>
                </td>
                <td class="interviewer" *ngIf="showInterviewer">{{w.interviewer}}</td>
                <td class="date" *ngIf="showTime">{{w.displayDate}}</td>
                <td class="time" *ngIf="showTime">{{w.displayTime}}</td>
            </tr>
        </tbody>
    </table>
</div>
