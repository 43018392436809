import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { InterviewerService } from '../interviewer.service';
import { UserFormService } from '@aifs-shared/forms/user-form.service';
import { MetaForm, MetaFormUserEvent, MFValueChange, UserEventType } from '@aifs-metaform/public-api';
import { Subscription, filter } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationDetails } from '../location.details';

@Component({
    selector: 'app-interview-loc',
    templateUrl: './interview-location.component.html',
    styleUrls: ['./style/interview-locations.scss']
})
export class InterviewLocationComponent implements OnInit, OnChanges, OnDestroy {
    @Input() location!: LocationDetails;
    @Input() interviewerId: number = -1;
    @Input() activeCount: number = 0;
    @Output() locationSelected: EventEmitter<LocationDetails> = new EventEmitter();
    @Output() locationDetailsChanged: EventEmitter<LocationDetails> = new EventEmitter();

    form: MetaForm;
    subscription?: Subscription;

    constructor(
        private interviewerService: InterviewerService,
        private formService: UserFormService,        
    ) {
        this.form = this.formService.locationActiveForm();
    }
    
    ngOnInit(): void {
        this.form.setValue('isActive', this.location.isActive);
        this.subscription = this.form.change$
            .pipe(
                filter(
                    (c: MFValueChange) => c.name === 'isActive')
            )
            .subscribe((chg: MFValueChange) => {
                this.location.isActive = chg.value;
                this.setActive(this.location.id);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['activeCount']) {
            this.onlyOne = (this.activeCount == 1 && this.location.isActive);
            this.atLimit = (this.activeCount > 7 && !this.location.isActive);
            this.canEditActiveStatus = !(this.onlyOne || this.atLimit);
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();   
    }    

    onClick(): void {
        this.locationSelected.emit(this.location);
    }

    onFormEvent(event: MetaFormUserEvent): void {
        switch (event.type) {
            case UserEventType.FormInitialised:
                break;
            case UserEventType.FormInvalid:
                break;
            case UserEventType.FormInvalidOnAttemptedSubmit:
                break;            
            case UserEventType.FormValid:
                break;
            case UserEventType.NavigationButtonClickedBack:
                break;
            case UserEventType.NavigationButtonClickedForward:
                break;            
            case UserEventType.FormSubmit:
                break;
        }
    }

    setActive(locationId: number) {        
        this.interviewerService
            .updateInterviewerLocation(
                this.interviewerId,
                this.location.id!,
                this.location.location.location.lat!,
                this.location.location.location.lng!,
                this.location.name!,
                this.location.availability!,
                this.location.isActive)
                .subscribe({
                    next: () => {
                        // this.getInterviewLocations();
                        this.locationDetailsChanged.emit(this.location);
                    },
                    error: (error: any) => {
                        let msg = error;
                        if (error instanceof HttpErrorResponse) { msg = `${error.statusText} (${error.status})`; }
                        console.error(msg);
                    }
                });
    }

    canEditActiveStatus = false;
    onlyOne = false;
    atLimit = false;
}