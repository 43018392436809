import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { ApplicantQueueItem, InterviewDetails, InterviewerRecord } from "./interviewer.service";

export type SortColumn = keyof InterviewerRecord | '';
export type UnconfirmedInterviewSortColumn = keyof InterviewDetails | '';
export type IncompleteInterviewReportSortColumn = keyof ApplicantQueueItem | '';

export type SortDirection = 'asc' | 'desc' | '';

export interface SortEvent {
    column: SortColumn;
    direction: SortDirection;
}

export interface IncompleteInterviewReportSortEvent {
    column: IncompleteInterviewReportSortColumn;
    direction: SortDirection;
}

export interface UnconfirmedInterviewSortEvent {
    column: UnconfirmedInterviewSortColumn;
    direction: SortDirection;
}

export interface IncompleteInterviewReportSortEvent {
    column: IncompleteInterviewReportSortColumn;
    direction: SortDirection;
}

@Directive({
    selector: 'th[sortable]',
    host: {
        '[class.asc]': 'direction === "asc"',
        '[class.desc]': 'direction === "desc"',
        '(click)': 'rotate()'
    }
})
export class NgbdSortableHeader {

    @Input() sortable: SortColumn = '';
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<SortEvent>();

    rotate() {
        this.direction = rotate[this.direction];
        const ev = { column: this.sortable, direction: this.direction } as SortEvent;
        this.sort.emit(ev);
    }
}

@Directive({
    selector: 'th[sortableiir]',
    host: {
        '[class.asc]': 'direction === "asc"',
        '[class.desc]': 'direction === "desc"',
        '(click)': 'rotate()'
    }
})
export class NgbdIncompleteInterviewReportSortableHeader {

    @Input() sortableiir: IncompleteInterviewReportSortColumn = '';
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<IncompleteInterviewReportSortEvent>();

    rotate() {
        this.direction = rotate[this.direction];
        const ev = { column: this.sortableiir, direction: this.direction } as IncompleteInterviewReportSortEvent;
        this.sort.emit(ev);
    }
}

@Directive({
    selector: 'th[sortableuci]',
    host: {
        '[class.asc]': 'direction === "asc"',
        '[class.desc]': 'direction === "desc"',
        '(click)': 'rotate()'
    }
})
export class NgbdUnconfirmedInterviewSortableHeader {

    @Input() sortableuci: UnconfirmedInterviewSortColumn = '';
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<UnconfirmedInterviewSortEvent>();

    rotate() {
        this.direction = rotate[this.direction];
        const ev = { column: this.sortableuci, direction: this.direction } as UnconfirmedInterviewSortEvent;
        this.sort.emit(ev);
    }
}

export const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

export const compare = (v1: string | undefined, v2: string | undefined) =>
    (v1 !== undefined && v2 !== undefined) ? (v1.localeCompare(v2, undefined, { sensitivity: 'base' })) : 0;

export const compareNumber = (v1: number | undefined, v2: number | undefined) =>
    (v1 !== undefined && v2 !== undefined) ? (v1 < v2 ? -1 : v1 > v2 ? 1 : 0) : 0;

export const compareBool = (v1: boolean | undefined, v2: boolean | undefined) =>
    (v1 !== undefined && v2 !== undefined) ? (v1 < v2 ? -1 : v1 > v2 ? 1 : 0) : 0;