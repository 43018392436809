<div class="profile">
    <img *ngIf="profileImage; else no_image" class="image" [class.clickable]="canEditImage" [src]="profileImage | cloudimageCrop:280:280">
    <ng-template #no_image>
        <img class="image no-image" [class.clickable]="canEditImage" [src]="noProfileImage | cloudimageCrop:280:280">
    </ng-template>
    <div class="edit" (click)="showUpload()"><i class="fa-solid fa-file-arrow-up"></i> Edit</div>
    <div class="caption">
        <span class="name">{{interviewer.firstName}} {{interviewer.lastName}}</span>
        <span class="status" [ngClass]="statusClass">{{interviewerStatus}}</span>
    </div>
</div>