import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AgentInterviewerDetails } from './interviewer.service';

@Component({
    selector: 'app-interviewer-details-sidebar',
    templateUrl: './interviewer-details-sidebar.component.html',
    styleUrls: ['./style/interviewer-details-sidebar.component.scss']
})
export class InterviewerDetailsSidebarComponent {
    @Input()
    interviewer?: AgentInterviewerDetails;
    
    get emailAddress(): string | undefined {
        return this.interviewer?.email || '';
    }
    
    get phone(): string | undefined {
        const t = this.interviewer?.telephoneNumber || undefined;
        if (t && t?.length > 1) return t;
        return undefined;
    }

    constructor(private router: Router) { }

    goToLocation(): void {
        this.router.navigateByUrl("/interviewer/details-location");
    }

}
