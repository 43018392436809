<h2>Interviewer Summary</h2>
<div class="summary">
    <div class="summary-content">
        <div class="calendar">
            <h3>Interviewer Availability</h3>
            <app-summary-calendar (selectedSlot)="calendarSlotSelected($event)" [filterCountry]="filterCountry"></app-summary-calendar>
        </div>

        <div class="selected-day">
            <div class="country-filter" *ngIf="showFilter">
                <span class="label"><i class="fa-solid fa-filter"></i></span>
                <div class="filter">
                    <lib-metaform-display *ngIf="filterForm" [form]="filterForm" [showButtons]="false" (formEvent)="onFormEvent($event)"></lib-metaform-display>            
                </div>
            </div>
            <div  *ngIf="hasSelected">
                <h3>{{selectedSlot}}</h3>
                <h4 *ngIf="available.length > 0">Available interviewers</h4>
                <div class="list">
                    <p class="info" *ngFor="let s of available">{{s}}</p>
                </div>
                <h4 *ngIf="unavailable.length > 0">Unavailable interviewers</h4>
                <div class="list">
                    <p class="info" *ngFor="let s of unavailable">{{s}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="panels">
        <div class="status-item info-card">
            <h3>Unconfirmed Interviews</h3>
            <app-upcoming-interviews [showNextOverhead]="false" [showTime]="true" [compact]="true" [filter]="'U'" [title]="''" [filterCountry]="filterCountry"></app-upcoming-interviews>
        </div>
        <div class="status-item info-card">
            <app-interview-report-incomplete [filterCountry]="filterCountry"></app-interview-report-incomplete>
        </div>
    </div>
</div>