import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InterviewerRoutingModule } from './interviewer-routing.module';
import { InterviewerSearchComponent } from './interviewer-search.component';
import { NgbdSortableHeader, NgbdUnconfirmedInterviewSortableHeader, NgbdIncompleteInterviewReportSortableHeader } from './NgbdSortableHeader';
import { TopMenuModule } from '@aifs-shared/menu/top-menu.module';
import { InterviewerProfileComponent } from './interviewer-profile.component';
import { UserModule } from '../user/user.module';
import { InterviewerCalendarComponent } from './interviewer-calendar.component';
import { CalendarModule } from '@aifs-shared/calendar/calendar.module';
import { InterviewerGeneralAvailabilityComponent } from './interviewer-general-availability.component';
import { UpcomingInterviewsComponent } from './upcoming-interviews.component';
import { InterviewReadyInterviewsComponent } from './interview-ready-interviews.component';
import { F2fFollowupInterviewsComponent } from  './f2f-followup-interviews.component';
import { InterviewerSidebarComponent } from './interviewer-sidebar.component';
import { PipesModule } from '@aifs-shared/pipe/pipes.module';
import { InterviewerProfileImageComponent } from './interviewer-profile-image.component';
import { InterviewerAvailabilityComponent } from './interviewer-availability.component';
import { IncompleteInterviewReportsComponent } from './incomplete-interview-reports.component';
import { CompletedInterviewsComponent } from './completed-interviews.component';
import { NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { UploadModule } from '@aifs-shared/upload/upload.module';
import { PromoteInterviewerModule } from '../promote-interviewer/promote-interviewer.module';
import { InterviewerSummaryComponent } from './interviewer-summary.component';
import { SummaryCalendarComponent } from './summary-calendar.component';
import { InterviewReportsReadyToSubmitComponent } from './interview-reports-ready-to-submit.component';
import { InterviewerDetailsComponent } from './interviewer-details.component';
import { MapModule } from '@aifs-shared/map/map.module';
import { InterviewerDetailsSidebarComponent } from './interviewer-details-sidebar.component';
import { CommentModule } from '@aifs-shared/comments/comment.module';
import { UpcomingInterviewNextComponent } from './upcoming-interview-next.component';
import { UpcomingInterviewsWeekComponent } from './upcoming-interviews-week.component';
import { UpcomingInterviewsTableComponent } from './upcoming-interviews-table.component';
import { UpcomingInterviewsModalComponent } from './upcoming-interviews-modal.component';
import { CompletedInterviewsModalComponent } from './completed-interviews-modal.component';
import { IncompleteInterviewReportsModalComponent } from './incomplete-interview-reports-modal.component';
import { InterviewerProfileBioModalComponent } from './interviewer-profile-bio-modal.component';
import { InterviewerAvailabilityModalComponent } from './interviewer-availability-modal.component';
import { InterviewLocationListComponent } from './locations/interview-location-list.component';
import { InterviewLocationEditComponent } from './locations/interview-location-edit.component';
import { InterviewLocationComponent } from './locations/interview-location.component';
import { MetaFormModule } from '@aifs-shared/ngx-metaform/metaform.module';

@NgModule({
    declarations: [
        InterviewerSearchComponent,
        InterviewerProfileComponent,
        InterviewerProfileBioModalComponent,
        InterviewerDetailsComponent,
        InterviewerDetailsSidebarComponent,
        InterviewerCalendarComponent,
        InterviewerAvailabilityComponent,
        InterviewerAvailabilityModalComponent, 
        InterviewerGeneralAvailabilityComponent,
        InterviewerSidebarComponent,
        InterviewerSummaryComponent,
        InterviewerProfileImageComponent,
        IncompleteInterviewReportsComponent,
        IncompleteInterviewReportsModalComponent,
        InterviewReportsReadyToSubmitComponent,
        CompletedInterviewsComponent,
        CompletedInterviewsModalComponent,
        UpcomingInterviewsComponent,
        UpcomingInterviewNextComponent,
        UpcomingInterviewsWeekComponent,
        UpcomingInterviewsTableComponent,
        UpcomingInterviewsModalComponent,
        InterviewReadyInterviewsComponent,
        F2fFollowupInterviewsComponent,
        SummaryCalendarComponent,
        NgbdSortableHeader,
        NgbdUnconfirmedInterviewSortableHeader,
        NgbdIncompleteInterviewReportSortableHeader,
        InterviewLocationListComponent,
        InterviewLocationComponent,
        InterviewLocationEditComponent,
    ],
    imports: [
        CommonModule,
        CommentModule,
        CalendarModule,
        InterviewerRoutingModule,
        MetaFormModule,
        NgbPopoverModule,
        NgbNavModule,
        PipesModule,
        PromoteInterviewerModule,
        TopMenuModule,
        UploadModule,
        UserModule,
        MapModule
    ],
    exports: [
        IncompleteInterviewReportsComponent,
        InterviewReportsReadyToSubmitComponent,
        CompletedInterviewsComponent,
        UpcomingInterviewsComponent,
        InterviewReadyInterviewsComponent,
        F2fFollowupInterviewsComponent,
    ]
})
export class InterviewerModule { }
