import { Component, Input, OnInit } from '@angular/core';
import { InterviewDetails, InterviewerService, UpcomingInterviews } from './interviewer.service';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpcomingInterviewsModalComponent } from './upcoming-interviews-modal.component';
import { Interview } from '../applicant/applicant.service';

@Component({
    selector: 'app-upcoming-interviews-week',
    templateUrl: './upcoming-interviews-week.component.html',
    styleUrls: ['./style/upcoming-interviews.component.scss']
})
export class UpcomingInterviewsWeekComponent implements OnInit {
    @Input() interviewerId = 0;
    @Input() showNext = true;
    @Input() showThisWeek = true;
    @Input() title?: string;
    @Input() showTime = false;
    @Input() compact = false;
    @Input() filter = "";
    @Input() showTable = true;
    @Input() next?: InterviewDetails
    @Input() thisWeek: InterviewDetails[] = [];
    @Input() filterCountry = '';

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private interviewer: InterviewerService,
        private userService: PortalUserService) {
        // console.log(`InterviewerId: ${this.interviewerId}`);
    }

    ngOnInit(): void {
        if (this.interviewerId == 0) {
            this.showInterviewer = true;
        } else {
            this.title = "Upcoming Interviews";
        }
        this.count = this.thisWeek.length;
    }

    openApplicant(next: InterviewDetails) {
        //console.log(`Open ${next.applicantId}/${next.applicationId}`);
        const link = `/applicant/${next.applicantId}/${next.applicationId}`;
        // console.log(`Showing applicant ${applicantId}`);
        this.router.navigate([]).then((result:any) => { window.open(link, '_blank'); });        
    }

    showTableDialog(): void {
        if (this.showTable) return;

        const modalRef = this.modalService.open(UpcomingInterviewsModalComponent, { size: 'lg', windowClass: 'modal', scrollable: true });
        modalRef.componentInstance.thisWeek = this.thisWeek;
        modalRef.componentInstance.showTime = this.showTime;
        modalRef.componentInstance.compact = this.compact;
        modalRef.componentInstance.filter = this.filter;
        modalRef.componentInstance.showInterviewer = this.showInterviewer;
        modalRef.componentInstance.title = 'Upcoming Interviews';
        modalRef.result.then().catch((err: any) => { });
    }

    isLoading = true;
    hasNext = true;
    hasForthcoming = true;
    count = 0;

    // next?: InterviewDetails;
    // thisWeek: InterviewDetails[] = [];
    noInterviews = "You have no interviews scheduled.";
    showInterviewer = false;
}