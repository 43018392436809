import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { InterviewDetails, InterviewerService } from './interviewer.service';
import { Router } from '@angular/router';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { NgbdUnconfirmedInterviewSortableHeader, UnconfirmedInterviewSortEvent, compare, compareBool, compareNumber } from './NgbdSortableHeader';

@Component({
    selector: 'app-upcoming-interviews-table',
    templateUrl: './upcoming-interviews-table.component.html',
    styleUrls: ['./style/upcoming-interviews.component.scss']
})
export class UpcomingInterviewsTableComponent implements OnChanges {
    @Input() thisWeek: InterviewDetails[] = [];
    @Input() showTime = false;
    @Input() compact = false;
    @Input() filter = "";
    @Input() filterCountry = '';
    @Input() showInterviewer = false;

    constructor(
        private router: Router,
        private interviewer: InterviewerService,
        private userService: PortalUserService) {
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['thisWeek']) {
            this.sortedList = this.thisWeek;
            // CA-3000 ensure that we update sortedFilteredList if no filter passed!
            this.filterByCountry();
        }
        if (changes['filterCountry']) {
            this.filterByCountry();
        }
    }

    private filterByCountry() {
        if (this.filterCountry === ''){
            this.sortedFilteredList = this.sortedList;
        }
        else{
            this.sortedFilteredList = this.sortedList.filter(i => i.countryCode === this.filterCountry);
        }
    }

    @ViewChildren(NgbdUnconfirmedInterviewSortableHeader) headers: QueryList<NgbdUnconfirmedInterviewSortableHeader> | undefined;
    onSort({ column, direction }: UnconfirmedInterviewSortEvent): void {
        
        if (!this.thisWeek) {
            console.log(`No data?`);
            return;
        }
        let sourceList = this.thisWeek;
        
        // // resetting other headers
        // this.headers.forEach(header => {
        //     if (header.sortable !== column) {
        //         header.direction = '';
        //     }
        // });
        // console.log(`Sorting: ${column}, ${direction}`);

        let list: InterviewDetails[] = [];

        // sorting countries
        if (direction === '' || column === '') {
            list = sourceList;
        } else {
            list = [...sourceList].sort((a, b) => {
                var res: number = 0;
                //if (typeof a[column] === "boolean") console.log(`Boolean: ${column}`);
                if (typeof a[column] === "boolean") {
                    // console.log(`boolean`);
                    res = compareBool(a[column] as boolean | undefined, b[column] as boolean | undefined);
                } else if (typeof a[column] === "string") {
                    console.log(`${column}: string`);
                    res = compare(a[column] as string | undefined, b[column] as string | undefined);
                } else if (typeof a[column] === "number") {
                    // console.log(`number`);
                    res = compareNumber(a[column] as number | undefined, b[column] as number | undefined);
                }
                return direction === 'asc' ? res : -res;
            });
        }

        this.sortColumn = column;
        this.sortDirection = direction;
        this.sortedList = list;
        this.filterByCountry();
    }

    sortType(column: string): string {
        if (column === this.sortColumn) {
            // console.log(`sorted! ${column}, ${this.sortDirection}`);
            return `${this.sortDirection}`;
        }
        return `unsorted`;
    }

    openApplicant(next: InterviewDetails) {
        //console.log(`Open ${next.applicantId}/${next.applicationId}`);
        const link = `/applicant/${next.applicantId}/${next.applicationId}`;
        // console.log(`Showing applicant ${applicantId}`);
        this.router.navigate([]).then((result: any) => { window.open(link, '_blank'); });
    }

    sortedList: InterviewDetails[] = [];

    sortColumn = 'interviewDateTime';
    sortDirection = 'asc';
    sortedFilteredList: InterviewDetails[] = [];
}