import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgencyInterviewerDetailsResponse, AgentInterviewerDetails, DeleteInterviewerLocationResponse, InterviewerLocation, InterviewerLocationsResponse, InterviewerService } from './interviewer.service';
import { UserFormService } from '@aifs-shared/forms/user-form.service';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MetaForm } from '@aifs-shared/ngx-metaform/metaform';
import { MFValueChange } from '@aifs-shared/ngx-metaform/metaform-data';
import { MetaFormUserEvent, UserEventType } from '@aifs-shared/ngx-metaform/ui/metaform-display.component';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal, ConfirmationModal } from '@aifs-shared/modals';
import { MapLocationImpl } from '@aifs-shared/map/map-location';
import { MapMarkerImpl, CAMapMarker } from '@aifs-shared/map/map-marker';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationDetails, LocationDetailsImpl } from './location.details';
import { InterviewLocationEditComponent } from './locations/interview-location-edit.component';

@Component({
    selector: 'app-interviewer-details',
    templateUrl: './interviewer-details.component.html',
    styleUrls: ['./style/interviewer-details.component.scss']
})
export class InterviewerDetailsComponent implements OnInit, OnDestroy {
    changeSubscription?: Subscription;


    locations: LocationDetails[] = [];
    isOnlineInterviewer: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private title: Title,
        private router: Router,
        private modalService: NgbModal,
        private interviewerService: InterviewerService,
        private userService: PortalUserService,
        private userForm: UserFormService) {
    }

    ngOnInit(): void {        
        this.interviewerId = this.userService.getInterviewerId();
        this.userId = this.userService.getUser().userId;
        sessionStorage.setItem('returnUrl', '/interviewer/details')

        this.getInterviewerDetails();
        this.getInterviewLocations();
    }

    ngOnDestroy(): void {
        if (this.changeSubscription) this.changeSubscription.unsubscribe();
    }

    getInterviewerDetails(): void {
        this.isLoading = true;
        this.interviewerService
            .getInterviewerDetails(this.interviewerId)
            .subscribe({
                next: (result: AgencyInterviewerDetailsResponse) => {
                    // console.log(`${JSON.stringify(result, null, 2)}`);
                    this.interviewer = result.details;
                    this.emailAddress = this.interviewer?.email;
                    this.phone = this.interviewer?.telephoneNumber;
                    this.isLoading = false;
                    this.title.setTitle(`${this.interviewer!.firstName} ${this.interviewer!.lastName} (${this.interviewerId})`);
                    
                    if (result?.details?.isOnlineInterviewer === false) {
                        this.isOnlineInterviewer = this.interviewer!.isOnlineInterviewer;
                    }

                    this.setupForm();

                    if (this.interviewer) {
                        this.form.setValue('personalNotes', this.interviewer!.notes);
                        this.form.setValue('interviewerBio', this.interviewer!.interviewerBio);
                        this.form.setValue('messageTemplate', this.interviewer!.messageTemplate);
                        this.changesMade = false;
                    }
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            })
    }
    
    private setupForm() {
        this.form = this.userForm.createInterviewerProfileForm(false, this.isOnlineInterviewer);
        this.changeSubscription = this.form.change$
            .subscribe((chg: MFValueChange) => {
                this.changesMade = true;
            });
    }


    saveChanges(): void {
        if (this.form) {
            this.isProcessing = true;
            const notes = this.form.getValue('personalNotes');
            const bio = this.form.getValue('interviewerBio');
            const msgTemplate = this.form.getValue('messageTemplate');
            this.interviewerService
                .updateBioAndNotes(this.interviewerId, notes, bio, msgTemplate)
                .subscribe({
                    next: (data: boolean) => {
                        this.isProcessing = false;
                        this.changesMade = false;
                        this.showAlert(`Details Updated`, `Your details have been updated.`);
                    },
                    error: (error: any) => {
                        this.isProcessing = false;
                        console.error(error);
                        this.showAlert(`Details`, `There was an error updating your details:`, error);
                    }
                });
        }
    }

    showAlert(title: string, explain: string, error: string | undefined = undefined) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = explain;
        modalRef.componentInstance.cssClass = error === undefined ? "info" : "danger";
        if (error)
            modalRef.componentInstance.error = error;

        modalRef.result.then(result => {
            if (result) {
            }
        }).catch((err: any) => { });
    }

    onFormEvent(event: MetaFormUserEvent): void {
        switch (event.type) {
            case UserEventType.FormInitialised:
                break;
            case UserEventType.FormInvalid:
                this.isValid = false;
                break;
            case UserEventType.FormValid:
                this.isValid = true;
                break;
            case UserEventType.NavigationButtonClickedBack:
                break;
            case UserEventType.NavigationButtonClickedForward:
                break;
            case UserEventType.FormSubmit:
                break;
        }
    }

    getInterviewLocations(): void{
        this.interviewerService.getInterviewerLocations(this.interviewerId)
            .subscribe({
                next: (result: InterviewerLocationsResponse) => {
                    const sorted = result.locations.sort((a, b) => { return ((a.interviewLocationId ?? 0) > (b.interviewLocationId ?? 0) ? -1 : 1) });
                    this.locations = sorted.map((location: InterviewerLocation) => 
                    {
                        return new LocationDetailsImpl(
                            location.interviewLocationId!,
                            location.name!,
                            new MapMarkerImpl(location.interviewLocationId!.toString(), 
                                    new MapLocationImpl(location.latitude!, location.longitude!), location.name!, location.name!),                            
                            location.availability!,
                            location.isActive!);
                    });
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            });
    }

    addLocation() {
        // CA-3049: NOTE(IAS): NS -> "There is no limit to how many locations an interviewer can add."
        // const maxLocations = 10;
        // const currentActiveLocations = this.locations.filter((location) => location.isActive);
        // if (currentActiveLocations.length >= maxLocations) {
        //     const modalRef = this.modalService.open(AlertModal);
        //     modalRef.componentInstance.title = "Cannot add location";
        //     modalRef.componentInstance.body = `Cannot add location. You cannot have more than ${maxLocations} active locations`;
        //     return;
        // }
        //this.router.navigate(['/interviewer/details-location', -1]);
        this.showAddEditLocationDialog();
    }

    getLocation(marker: CAMapMarker) {
        return [marker]
    }

    // edit(locationId: number) {
    //     this.router.navigate(['/interviewer/details-location', locationId]);
    // }

    onLocationSelected(location: LocationDetails): void {
        // console.log(`Display edit dialog for ${JSON.stringify(location, null, 2)}`);
        this.showAddEditLocationDialog(location);
    }

    showAddEditLocationDialog(location?: LocationDetails): void {
        const modalRef = this.modalService.open(InterviewLocationEditComponent, { size: 'lg', windowClass: 'modal-availability', scrollable: true });
        modalRef.componentInstance.locationId = location ? location.id : -1;
        modalRef.componentInstance.location = location;
        modalRef.componentInstance.activeLocationCount = this.locations.filter(l => l.isActive).length;
        modalRef.componentInstance.interviewerId = this.interviewer?.id;
        modalRef.result.then((result: any) => {
            if (result) {
                this.getInterviewLocations();
            }
        }).catch((err: any) => { });
    }

    // remove(locationId: number){
    //     const modalRef = this.modalService.open(ConfirmationModal);
    //     modalRef.componentInstance.title = 'Remove location';
    //     modalRef.componentInstance.body = 'Are you sure you want to remove the location?';
    //     modalRef.result.then(result => {
    //         if (result) {
    //             this.interviewerService.deleteInterviewerLocation(this.interviewerId, locationId)
    //                 .subscribe({
    //                     next: (result: DeleteInterviewerLocationResponse) => {
    //                         if (result.wasDeleted) {
    //                             this.getInterviewLocations();
    //                         } else {
    //                             this.isLoading = false;
    //                             const modalRef = this.modalService.open(AlertModal);
    //                             modalRef.componentInstance.title = "Could not delete location";
    //                             modalRef.componentInstance.body = "The location could not be deleted because there are interviews scheduled for this location.";
    //                         }
    //                     },
    //                     error: (error) => {
    //                         console.error(error);
    //                         this.isLoading = false;
    //                     }
    //                 });
    //         }
    //     }).catch((err: any) => { });    
    // }

    interviewer?: AgentInterviewerDetails;

    userId = -1;
    interviewerId = -1;
    isLoading = true;

    isValid = false;
    isProcessing = false;
    changesMade = false;

    emailAddress?: string;
    phone?: string;

    public form!: MetaForm
}
