import { Component, OnInit, Input, Output, ElementRef, NgZone, EventEmitter, ViewChild, ViewChildren, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

import { MapLocation, MapLocationImpl } from './map-location';
import { CAMapMarker, MapMarkerImpl } from './map-marker';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./less/map.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

/*
 * Note(IAS) 02/11/2022
 * We are using the inline script for loading the maps api and libraries
 * This can be improved by using an inline/lazy api loader, however
 * every version I've tried ends up reloading the api scripts multiple
 * times and really hasn't proved to be worth the four hours I spent
 * attempting to debug it. 
 * 
 * If you do end up wishing to revisit this, just heed the warning that
 * any lazy loader requires some form of static/replayable observable
 * that is guaranteed not to attempt to load the api more than once
 * per 'session', e.g. revisiting the page multiple times without
 * a full refresh of the browser.
 */

export class MapComponent implements OnInit, AfterViewInit {
    @ViewChild('search') public searchElementRef!: ElementRef;
    @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
    @ViewChild(GoogleMap) googleMap!: GoogleMap;
    @ViewChildren('marker') markerElements!: any;

    @Input()
    get mapMarkers(): CAMapMarker[] {
        return this.markerList.slice();
    }

    set mapMarkers(value: CAMapMarker[]) {
        // console.log(`Getting markers`, value);

        this.markerList = value.slice() || [];

        const markers: { position: { lat: number; lng: number; }; label: { color: string; text: string | undefined; }; icon: string; title: string; info: string; options: { animation: number; }; }[] = [];
        const markerPositions: google.maps.LatLngLiteral[] = [];

        this.markerList.forEach(mm => {
            const m = {
                position: {
                    lat: mm.location.lat,
                    lng: mm.location.lng,
                },
                label: {
                    color: 'blue',
                    text: mm.label,
                },
                icon: mm.iconUrl,
                title: mm.title,
                info: 'Marker info ' + (markers.length + 1),
                options: {
                    animation: 2,
                },
            }
            markers.push(m);
            markerPositions.push(m.position);
        });

        this.markers = markers.slice();
        this.markerPositions = markerPositions.slice();

        this.setBounds();
    }

    @Input()
    get selectedMarker(): CAMapMarker {
        return this.selectedMapMarker;
    }
    set selectedMarker(value) {
        this.setSelectedMarker(value);
    }

    @Input()
    panToLocation!: CAMapMarker;

    @Input()
    showSearchTextbox: boolean = true;

    @Input()
    showLocation: boolean = true;

    @Output()
    currentLocationChanged: EventEmitter<MapLocation> = new EventEmitter<MapLocation>();

    @Output()
    locationSelected: EventEmitter<CAMapMarker> = new EventEmitter<CAMapMarker>();

    @Output()
    infoWindowAction: EventEmitter<string> = new EventEmitter<string>();

    constructor(private ngZone: NgZone) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.safeToUse = true;

        this.getCurrentLocation()
            .then((location: any) => {
                if (this.markerList.length == 0) {
                    // console.log(`Position: ${JSON.stringify(location)}`);
                    this.locationPosition = new google.maps.LatLng(location.lat, location.lng);
                    this.currentLocationChanged.emit(new MapLocationImpl(this.locationPosition.lat(), this.locationPosition.lng(), ''));

                    this.setMapCenter(this.locationPosition);
                    this.setBounds();
                }
            });

        let autocomplete: google.maps.places.Autocomplete| undefined = undefined;

        if (this.searchElementRef) {
            // Binding autocomplete to search input control
            autocomplete = new google.maps.places.Autocomplete(
                this.searchElementRef.nativeElement
            );
        }

        if (autocomplete) {
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    // console.log(`Got a place?`);
                    let place: google.maps.places.PlaceResult = autocomplete!.getPlace();
                    if (place) {
                        //verify result
                        if (place.geometry === undefined || place.geometry === null) {
                            return;
                        }

                        const id = place.place_id ?? '';
                        // console.log({ place }, place.geometry.location?.lat());

                        //set latitude, longitude and zoom
                        this.locationPosition = new google.maps.LatLng(place.geometry.location!.lat(), place.geometry.location!.lng());
                        this.setBounds();

                        this.currentLocationChanged.emit(new MapLocationImpl(this.locationPosition.lat(), this.locationPosition.lng(), id));
                        // console.log(`Sent location changed`);

                    }
                });
            });
        }
    }

    mapClicked(event: any): void {
        // console.log(`mapClicked:`, event);
        let placeId = '';
        let lat = 0;
        let lng = 0;
        if (event.placeId) {
            placeId = event.placeId;
        }

        if (event.latLng) {
            lat = event.latLng.lat();
            lng = event.latLng.lng();
        }

        const marker = new MapLocationImpl(lat, lng, placeId);
        // console.log(`Marker is: ${JSON.stringify(marker, null, 2)}`);
        this.currentLocationChanged.emit(marker);
    }

    /**
     * Set the selected marker on this page
     * @param marker (MapMarker)
     */
    public setSelectedMarker(marker: CAMapMarker) {
        // console.info(`Setting selected marker for list: ${JSON.stringify(marker,null,2)}`);
        if (this.selectedMapMarker) {
            let old = this.markers.find((m: { id: string; }) => m.id == this.selectedMapMarker.id);
            if (old) {
                old.iconUrl = MapMarkerImpl.markerIcon(false);
            }
        }

        if (marker) {
            let newM = this.markers.find((m: { id: string; }) => m.id == marker.id);
            if (newM) {
                newM.iconUrl = MapMarkerImpl.markerIcon(true);
            }

            let markerList = this.markers.slice();
            //this.mapMarkers = markerList;
            if (this.googleMap) {
                this.googleMap.panTo(marker.location);
                if (this.googleMap.getZoom()! < 12) this.zoomLevel = 12;
            }
            this.infoText = `<h4>${marker.title}</h4>`;

            let mapMarkerIdx = -1;
            for(let i=0;i<this.markers.length;i++) {
                if(this.markers[i].title === marker.title) {
                    mapMarkerIdx = i;
                    break;
                }
            }

            if(mapMarkerIdx > -1 && this.infoWindow) {  
                this.infoWindow.open(this.markerElements.toArray()[mapMarkerIdx]);
            }
        }

        this.selectedMapMarker = marker;
    }

    /**
     * Ask the browser for the user's current position
     */
    getCurrentLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        if (position) {
                            let lat = position.coords.latitude;
                            let lng = position.coords.longitude;

                            const location = {
                                lat,
                                lng,
                            };
                            resolve(location);
                        }
                    },
                    (error) => console.log(error)
                );
            } else {
                reject('Geolocation is not supported by this browser.');
            }
        });
    }

    infoText: string = "";

    openInfoWindow(marker: MapMarker, windowIndex: number) {
        /// stores the current index in forEach
        if (this.infoWindow) {
            const mm = this.markerList[windowIndex];
            if (mm) {
                // console.log(`openInfoWindow: Got marker: ${JSON.stringify(mm)}`);
                this.locationSelected.emit(mm);
                this.infoText = `<h4>${mm.title}</h4>`;
                this.infoWindow.open(marker);
            }
        }
    }

    /**
     * The user has clicked the info window action button
     * @param action (string) the action name from the info window
     */
    infoWindowActionTriggered(action: string): void {
        this.infoWindowAction.emit(action);
    }

    /**
     * Set the center of the map
     * @param c 
     */
    setMapCenter(c: google.maps.LatLng) {
        if (c) {
            this.mapCenter = c;
            this.googleMap!.panTo(c);
        }
    }

    // Default map options
    // https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    options!: google.maps.MapOptions;

    setBounds(): void {
        if (!this.safeToUse) return;
        var bounds = new google.maps.LatLngBounds();
        if (this.locationPosition) {
            bounds.extend(this.locationPosition);
        }
        this.markerPositions.forEach(mp => bounds.extend(mp));
        this.googleMap!.fitBounds(bounds);
        this.googleMap!.center = bounds.getCenter();

        // console.log(`detect changes`);
        // this.ref.detectChanges();
    }

    locationMarkerOptions: google.maps.MarkerOptions = { draggable: false, icon: "https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png" };
    locationPosition?: google.maps.LatLng;

    markerOptions: google.maps.MarkerOptions = {
        draggable: false,
        animation: google.maps.Animation.DROP,
        clickable: true
    };

    mapOptions: google.maps.MapOptions = {
        zoom: 13,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
    };

    markerPositions: google.maps.LatLngLiteral[] = [];

    // apiLoaded: Observable<boolean>;
    safeToUse = false;
    googlemap!: google.maps.Map;
    mapCenter!: google.maps.LatLng;

    currentLocation!: CAMapMarker;
    markerList: CAMapMarker[] = [];
    markers = [] as any;

    animation: any;
    selectedMapMarker!: CAMapMarker;
    zoomLevel: number = 11;
}
