import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@aifs-shared/user/user.service';
import { CacheService } from '@aifs-shared/cache/cache.service';
import { environment } from '@environments/environment';
import { AgencyInterviewerDetailsResponse, AgentInterviewerDetails, InterviewerService } from './interviewer.service';
import { PortalUserPermission, PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChooseUploadTypeComponent } from '@aifs-shared/upload/choose-upload-type.component';
import { DocumentType } from '@aifs-shared/upload/document-type';
import { InterviewerDetailChanged } from './interviewer-detail-changed';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-interviewer-profile-image',
    templateUrl: './interviewer-profile-image.component.html',
    styleUrls: ['./style/interviewer-profile-image.component.scss']
})
export class InterviewerProfileImageComponent implements OnInit, OnDestroy {

    @Input() interviewer!: AgentInterviewerDetails;
    // @Input() cropSize: string = '300:300'; // How to make this work?

    constructor(
        private modalService: NgbModal,
        private cache: CacheService,
        private userService: PortalUserService,
        private interviewerService: InterviewerService
    ) { }

    ngOnInit(): void {
        this.canEditImage = this.userService.hasPermission(PortalUserPermission.EditInterviewer)
        if (this.interviewer) {
            this.profileImage = this.interviewer.profileURL;
            this.interviewerStatus = this.interviewer.activeForWeb ? "Active" : "Inactive";
            this.statusClass = this.interviewer.activeForWeb ? "int-active" : "int-inactive";
        } else {
            console.warn(`I didn't get an interviewer!`);
        }

        this.changeSubscription = this.interviewerService.interviewerDetailsChanged$
            .subscribe({
                next: (changeItem: InterviewerDetailChanged) => {
                    // console.log(`Changed!`);
                    if (this.interviewer!.id === changeItem.interviewerId) {
                        this.interviewerService
                            .getInterviewerDetails(changeItem.interviewerId)
                            .subscribe({
                                next: (result: AgencyInterviewerDetailsResponse) => {
                                    this.profileImage = result?.details!.profileURL;
                                },
                                error: (error) => {
                                    console.error(error);
                                }
                            });
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.changeSubscription.unsubscribe();
    }

    showUpload(): void {
        const modalRef = this.modalService.open(ChooseUploadTypeComponent, { size: 'md', windowClass: 'upload-modal', scrollable: true });
        modalRef.componentInstance.interviewerId = this.interviewer.id;
        modalRef.componentInstance.documentTypeId = DocumentType.InterviewerPortrait;
        modalRef.componentInstance.deleteOriginal = this.profileImage && this.profileImage.startsWith('https') ? true : false;
        modalRef.componentInstance.title = 'New Interviewer Profile Image';
    }

    profileImage?: string;

    noProfileImage = environment.resources.imageNoProfileUrl;
    interviewerStatus = "";
    statusClass = "";
    canEditImage = false;
    changeSubscription!: Subscription;
}