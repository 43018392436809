<div class="modal-detail">
    <div class="modal-header">
        <h2>Interviewer Profile</h2>
        <span class="dismiss" (click)="closeModal()"><i class="far fa-times-circle"></i></span>
    </div>
    <div class="modal-body">
        <div class="interviewer-details" *ngIf="form">
            <lib-metaform-display [form]="form" [showButtons]="false" (formEvent)="onFormEvent($event)"></lib-metaform-display>
            <div class="button-bar">
                <button type="button" class="btn btn-success" [disabled]="!(isValid && changesMade) || isProcessing" (click)="saveChanges()">Save<img *ngIf="isProcessing" src="img/white-loader.gif"></button>
                <button type="button" class="btn btn-danger" (click)="closeModal()">Cancel</button>
            </div>
        </div>
        <div class="buttons">
        </div>
    </div>
</div>