<h3>Interview Ready <span *ngIf="count > 0">{{count}}</span></h3>
<div class="incomplete" *ngIf="!isLoading; else still_loading">
    <table *ngIf="!noData" class="table table-striped table-fixed">
        <tbody>
            <tr *ngFor="let a of applicants" class="applicant" (click)="openApplicant(a)">
                <td>
                    <app-round-image class="profile-image" [url]="a.profile" [useDefaultMissingImage]="true" [width]="30"
                        [height]="30"></app-round-image>
                </td>
                <td class="new-applicant"><i *ngIf="a.isNewToUser" class="fa-solid fa-circle-plus"></i></td>
                <td>{{a.name}}</td>               
                <td class="state">{{a.userState}}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="noData">
        <p>No applicants ready for an interview.</p>
    </div>
</div>
<ng-template #still_loading>
    <div class="loading">
        <div class="load_container">
            <img src="img/stopwatch.svg" class="bl">
        </div>
    </div>
</ng-template>