import { DateTime } from 'luxon';

export class Month {

    /**
     * @description Figure out the days and weeks within the passed month and year
     * @param zbmonth - zero indexed month
     * @param year  - current year
     */
    public static calculate(zbmonth: number, year: number): Month {
        var m = new Month();

        // Clear
        m.weeks = [];

        const month = zbmonth + 1;
        m.next = zbmonth + 1;
        m.previous = zbmonth - 1;
        
        m.nextYear = year;
        m.previousYear = year;

        if (m.next > 11) {
            m.next = 0;
            m.nextYear++;
        }
        if (m.previous < 0) {
            m.previous = 11;
            m.previousYear--;
        }

        // console.log(`Let's build a month! ${month}/${year}`);
        const activeDay = DateTime.now();
        // console.log(`Current day = ${activeDay.toISODate()}`);

        const targetMonth = DateTime.fromObject({ year: year, month: month, day: 1 });
        // console.log(`Luxon Date Start: ${targetMonth.toLocaleString(DateTime.DATETIME_MED)}`);

        // Calculate days in month: 
        const daysInMonth = targetMonth.daysInMonth;
        // console.log(`Luxon: there are ${daysInMonth} days in ${targetMonth.monthLong}`);
        m.name = targetMonth.monthLong!;
        m.year = targetMonth.year;

        // First week/starting day
        // Luxon/ISO has Monday as the first day of the week
        const startDate = targetMonth.startOf('week');
        const endDate = targetMonth.endOf('month');
        // console.log(`Luxon: Week starts on ${startDate.toISODate()}`);

        var runningDay = 0;
        var dayInWeek = 1;

        // Create first week
        var week = new LxWeek(0);
        var weekCount = 1;
        var finished = false;
        var pastEndOfMonth = false;
        while (!(finished)) {
            // Create a new day
            const todayDate = startDate.plus({ days: runningDay });
            const isInTargetMonth = targetMonth.month === todayDate.month;
            
            pastEndOfMonth = (todayDate > endDate);
            var today = new LxDay(todayDate, dayInWeek, isInTargetMonth);
            // console.log(`Luxon: day ${runningDay}/${dayInWeek}: ${todayDate.toISODate()}`);

            if (todayDate.toISODate() == activeDay.toISODate()) {
                today.isToday = true;
            }

            week.days.push(today);

            dayInWeek++;
            if (dayInWeek > 7) {

                const tomorrowDate = startDate.plus({ days: runningDay + 1 });                
                // console.log(`At end of week: ${(tomorrowDate > endDate)}`);

                m.weeks.push(week);
                if (week.contains(activeDay)) {
                    // console.log(`Active day is ${activeDay.toISODate()} Week is ${m.weeks.length - 1}`);
                    m.activeWeek = m.weeks.length - 1;
                }

                // Back to Monday; start a new slot
                dayInWeek = 1;

                if (pastEndOfMonth || tomorrowDate > endDate) {
                    // console.log(`Luxon: past the end of month and completed the final week`);
                    finished = true;
                    break;
                } else {
                    week = new LxWeek(weekCount);
                    weekCount++;
                }
            }

            runningDay++;
        }

        // console.log(`Luxon says there are ${m.weeks.length + 1} weeks in ${targetMonth.monthLong}`);

        return m;
    }

    name: string = "";
    year: number = 0;
    weeks: LxWeek[] = [];
    previous: number = 0;
    previousYear: number = 0;
    next: number = 0;
    nextYear: number = 0;
    activeWeek: number = 0;
}

export class LxDay {
    constructor(date: DateTime, day: number, isInTargetMonth: boolean) {
        this.date = date;
        this.dayOfWeek = day;
        this.day = date.day;
        this.isInTargetMonth = isInTargetMonth;
    }
    day: number;
    dayOfWeek: number;
    date: DateTime;
    isInTargetMonth: boolean;
    isToday = false;
}

export class LxWeek {
    constructor(id: number) {
        this.id = id;    
    }

    contains(d: DateTime): boolean {
        return (d >= this.days[0].date && d <= this.days[6].date);
    }

    public getFirstDate(): string {
        return this.days[0].date.toISODate()!;
    }

    public getLastDate(): string {
        return this.days[6].date.toISODate()!;
    }

    public getLastDateForRange(): string {
        return this.days[6].date.plus({days: 1}).toISODate()!;
    }

    public getTitle(): string {
        if (this.days.length > 0) {
            const f = this.days[0].date;
            const l = this.days[this.days.length-1].date;

            return `${f.toFormat('MMMM')} ${this.ordinal(f.day)} - ${l.toFormat('MMMM')} ${this.ordinal(l.day)}, ${l.year}`;
        } else {
            return `There are only ${this.days.length} days in this week?`;
        }
    }
    
    id: number;
    days: LxDay[] = [];

    private ordinal(n: number): string {
        const ordinalRules = new Intl.PluralRules("en", {
            type: "ordinal"
        });
        const suffixes = {
            zero: '',
            one: "st",
            two: "nd",
            few: "rd",
            many: '',
            other: "th"
        };
        const suffix = suffixes[ordinalRules.select(n)];
        return (n + suffix);
    }
}