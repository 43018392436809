export interface MapLocation {
    lat: number;
    lng: number;
    placeId: string;
}

export class MapLocationImpl implements MapLocation {
    public lat: number;
    public lng: number;
    public placeId: string;
    constructor(lat: number, long: number, placeId?: string) {
        this.lat = lat;
        this.lng = long;
        this.placeId = placeId ?? '';
    }
}
