<div *ngIf="hasNext && next" class="next" (click)="openApplicant(next!)">
    <h3>Your next interview</h3>
    <div class="interviews">
        <app-round-image [url]="next.profile"></app-round-image> 
        <div class="items">
            <span class="item">{{next!.name}}</span>
            <span class="item time"><i class="fa-regular fa-clock"></i> {{next!.date}}, {{next!.hour}}</span>
        </div>  
    </div>
</div>

