import { UserMessageComponent } from '@aifs-shared/message/user-message.component';
import { PortalUserPermission, PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { AzureLoginResponse, RoleName } from '@aifs-shared/user/user';
import { UserService } from '@aifs-shared/user/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentInterviewerDetails } from './interviewer.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-interviewer-sidebar',
    templateUrl: './interviewer-sidebar.component.html',
    styleUrls: ['./style/interviewer-sidebar.component.scss']
})
export class InterviewerSidebarComponent implements OnInit {

    @Input() interviewer!: AgentInterviewerDetails;

    constructor(
        route: ActivatedRoute,
        private modalService: NgbModal,
        private userService: UserService,
        private portalUserService: PortalUserService) {
    }

    ngOnInit(): void {
        const r = this.interviewer;
        this.selectedInterviewer = r.id;

        this.emailAddress = r.email;
        this.azureLogin = r.azureLogin;
        this.phone = r.mobileNumber;
        this.country = r.agencyCountry;
        this.region = r.regions;
        this.agency = r.agencyName;
        if (r.lastLoginDays) {
            this.lastLoginDays = r.lastLoginDays;
        }

        this.applicantLimit = r.applicantLimit; 

        this.canCreateAzureLogins = this.portalUserService.hasPermission(PortalUserPermission.UserCreateAzureLogin);
    }

    sendMessage() {
        const modalRef = this.modalService.open(UserMessageComponent, { size: 'md', windowClass: 'message-modal', scrollable: false });
        modalRef.componentInstance.recipientUserId = this.interviewer.userid;
        modalRef.componentInstance.recipientName = this.interviewer.firstName;
        modalRef.componentInstance.title = 'Message';        
    }

    createAzureLogin() {
        if (!environment.production && this.makeSureClicksNo > 0) {
            const alertMsg = `Test env: Click ${this.makeSureClicksNo} more times to create Azure account. Delete user in portal.azure.com after testing.`;
            this.makeSureClicksNo--;
            alert(alertMsg);
            return;
        }
      
        this.userService.createAzureLogin(this.interviewer.userid).subscribe((success: AzureLoginResponse) => {
            this.interviewer.azureLogin = success.azureLogin;
            this.azureLogin = success.azureLogin;
        });
    }


    azureLoginSet = () : boolean => this.azureLogin != '' && !this.azureLogin?.endsWith('@unset.com');

    canCreateAzureLogins = false;
    azureLogin?: string;

    showActions = true;
    selectedInterviewer: number = 0;
    hasLoggedIn = false;
    applicantState = '';
    emailAddress?: string;
    phone?: string;
    dob?: string;
    country?: string;
    region?: string;
    agency?: string;
    lastLogin?: number;
    lastLoginDays?: string;
    makeSureClicksNo = 3;

    applicantLimit?: number;
}
