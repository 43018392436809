<div class="modal-detail">
    <div class="modal-header">
        <h2>Calendar</h2>
        <span class="dismiss" (click)="closeModal()"><i class="far fa-times-circle"></i></span>
    </div>
    <div class="modal-body">
        <div class="calendar">
            <div *ngIf="displayMode=='G'" class="calendar-container">
                <div class="">
                    <div class="controls">
                        <i (click)="changeWeek(-1)" class="fa-solid fa-chevron-left"></i>
                        <h2 class="week">{{weekName}}</h2>
                        <i (click)="changeWeek(1)" class="fa-solid fa-chevron-right"></i>
                    </div>
                    <app-calendar [class.refresh]="isRefreshing" [days]="days" (itemSelected)="calendarItemSelected($event)"></app-calendar>
                </div>
            </div>   
            <div *ngIf="displayMode=='E'" class="calendar-container">
                <h2>Set the general availability for interviews</h2>
                <app-time-slot [canEdit]="canEdit" [days]="days" (itemSelected)="timeSlotSelected($event)"></app-time-slot>
            </div> 
        </div>
        <div class="actions">
            <div *ngIf="displayMode=='G'">
                <button *ngIf="canEdit" class="button btn-wide btn-primary" (click)="changeAvailability()">Edit availability</button>
                <button type="button" class="btn btn-primary btn-wide" [disabled]="!changesMade || isProcessing"
                    (click)="saveChanges()">Save changes <img *ngIf="isProcessing" src="img/white-loader.gif"></button>
            </div>
            <div *ngIf="displayMode=='E'">
                <button class="button btn-wide btn-danger" (click)="cancelChangeAvailability()">Cancel</button>
                <button type="button" class="btn btn-primary btn-wide" [disabled]="!changesMadeEditMode || isProcessing" (click)="saveChangedGeneral()">Save Changes
                    <img *ngIf="isProcessing" src="img/white-loader.gif"></button>
            </div>
        </div>
    </div>
</div>