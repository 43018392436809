
<div class="interviewer-page">
    <div class="interviewer-sidebar">
        <div *ngIf="interviewer">
            <app-interviewer-profile-image [interviewer]="interviewer"></app-interviewer-profile-image>
            <app-interviewer-sidebar class="applicant-card" [interviewer]="interviewer"></app-interviewer-sidebar>
        </div>
    </div>

    <div class="interviewer-content">
        <div class="interviewer-banner">
            <div class="banner-buttons">
                <app-promote-button *ngIf="interviewer" [interviewer]="interviewer"></app-promote-button>
            </div>
        </div>
        <div class="interviewer-details">
            <div class="main">
                <div class="actions">
                    <button type="button" [disabled]="activeButtonDisabled" class="btn" (click)="toggleActiveForWeb()" [class.btn-danger]="interviewer?.activeForWeb" [class.btn-success]="!interviewer?.activeForWeb">{{activeButtonText}}</button>
                </div>
            </div>
            <div class="info">
                <div class="status-components" *ngIf="selectedInterviewerId">
                    <div class="status-item availability info-card clickable" (click)="showAvailability()">
                        <h3>Availability</h3> 
                        <app-interviewer-availability *ngIf="selectedInterviewerId" [displaySummary]="true" [interviewerId]="selectedInterviewerId"></app-interviewer-availability>
                    </div>
                    <div class="status-item upcoming info-card">
                        <app-upcoming-interviews [interviewerId]="selectedInterviewerId" [showNextOverhead]="false" [showTime]="true" [compact]="true" [showTable]="false"></app-upcoming-interviews>
                    </div>
                    <div class="status-item incomplete info-card">
                        <app-interview-report-incomplete [showTable]="false" [interviewerId]="selectedInterviewerId"></app-interview-report-incomplete>
                    </div>
                    <div class="status-item completed info-card">
                        <app-interview-complete [showTable]="false" [interviewerId]="selectedInterviewerId"></app-interview-complete>
                    </div>
                </div>
                <div class="comment-area" *ngIf="selectedInterviewerId">
                    <div class="profile-bio info-card" (click)="viewInterviewerProfile()">
                        <h2>Profile</h2>
                    </div>
                    <div class="comments info-card">
                        <app-int-comment-display [interviewerId]="selectedInterviewerId"></app-int-comment-display>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>