import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InterviewerLocation, InterviewerLocationsResponse, InterviewerService } from '../interviewer.service';
import { UserFormService } from '@aifs-shared/forms/user-form.service';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { MetaForm } from '@aifs-shared/ngx-metaform/metaform';
import { MFValueChange } from '@aifs-shared/ngx-metaform/metaform-data';
import { MetaFormUserEvent, UserEventType } from '@aifs-shared/ngx-metaform/ui/metaform-display.component';
import { Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapLocationImpl } from '@aifs-shared/map/map-location';
import { MapMarkerImpl, CAMapMarker } from '@aifs-shared/map/map-marker';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationDetails, LocationDetailsImpl } from '../location.details';
import { MapPlace, MapService } from '@aifs-shared/map/map.service';

@Component({
    selector: 'app-interview-loc-edit',
    templateUrl: './interview-location-edit.component.html',
    styleUrls: [
        '../../shared/modals/style/modal-styles-generic.scss',
        './style/interview-locations.scss',
    ]
})
export class InterviewLocationEditComponent implements OnInit, OnDestroy {
    @Input() interviewerId = -1;
    @Input() locationId: number = -1;
    @Input() location?: LocationDetails;
    @Input() activeLocationCount = 0;

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private interviewerService: InterviewerService,
        private userService: PortalUserService,
        private userForm: UserFormService,
        private maps: MapService) {        
            this.form = this.userForm.locationEditForm();
    }
        
    ngOnInit(): void {
        this.showSearchBox = this.locationId == -1;
        if (this.locationId == -1) {
            this.title = 'Add Location';
            this.location = new LocationDetailsImpl(-1, "", new MapMarkerImpl("", new MapLocationImpl(0, 0), "", ""), "", false);
        }
        
        this.interviewerId = this.userService.getInterviewerId();
        this.userId = this.userService.getUser().userId;
        this.changeSubscription = this.form.change$
            .subscribe((chg: MFValueChange) => {
                this.changesMade = true;
                this.isValid = this.form.isValid();
            });


        this.getLocationDetails();    
        this.getInterviewLocations();
    }

    closeModal() {
        // CA-3040 NS doesn't want this
        // if (this.changesMade) {
        //     // const modalRef = this.modalService.open(ConfirmationModal);
        //     // modalRef.componentInstance.title = 'Discard Changes';
        //     // modalRef.componentInstance.body = `Are you sure you want to discard your changes?`;
        //     // modalRef.result.then((result: boolean) => {
        //     //     if (result) this.modalService.dismissAll();
        //     // }).catch((err: any) => { });
        // } else {
        //     // this.modalService.dismissAll();
        // }
        this.modalService.dismissAll();
    }

    getLocationDetails() {
        if (this.locationId == -1) { 
            this.location = new LocationDetailsImpl(-1, "", new MapMarkerImpl("", new MapLocationImpl(0, 0), "", ""), "", false);
        } else {
            this.interviewerService.getInterviewerLocations(this.interviewerId)
            .subscribe({
                next: (result: InterviewerLocationsResponse) => {
                    
                    this.location = result.locations
                        .filter((location: InterviewerLocation) => location.interviewLocationId == this.locationId)
                        .map((location: InterviewerLocation) => 
                        {
                            return new LocationDetailsImpl(
                                location.interviewLocationId!,
                                location.name!,
                                new MapMarkerImpl(location.interviewLocationId!.toString(), 
                                        new MapLocationImpl(location.latitude!, location.longitude!), location.name!, location.name!),                            
                                location.availability!,
                                location.isActive!);
                        })[0];     
                    this.form.setValue("locationName", this.location.name);
                    this.form.setValue("locationAvailability", this.location.availability);         
                    this.hasPin = true;
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.changeSubscription) this.changeSubscription.unsubscribe();
    }

    getLocation(marker: CAMapMarker) {
        return [marker]
    }

    currentLocationChanged(event: any) {
        //console.log(`Got location:`, event);
        if (event.placeId) {
            // console.log(`Got place: ${event.placeId}`);
            this.maps.getPlace(event.placeId).subscribe({
                next: (result: MapPlace) => {
                    this.form.setValue('locationName', `${result.name} - ${result.address}`);
                    this.hasPin = true;

                },
                error: (error: any) => {
                    console.warn(`Got error from getPlace`, error);
                }
            });
        }

        if (this.location) {
            this.location.location = new MapMarkerImpl("id1", 
                new MapLocationImpl(event.lat as number, event.lng as number), "", "");
        }
    }

    getInterviewLocations(): void{
        this.interviewerService.getInterviewerLocations(this.interviewerId)
            .subscribe({
                next: (result: InterviewerLocationsResponse) => {
                    this.locations = result.locations.map((location: InterviewerLocation) => 
                    {
                        return new LocationDetailsImpl(
                            location.interviewLocationId!,
                            location.name!,
                            new MapMarkerImpl(location.interviewLocationId!.toString(), 
                                    new MapLocationImpl(location.latitude!, location.longitude!), location.name!, location.name!),                            
                            location.availability!,
                            location.isActive!);
                    });
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            });
    }

    cancel() {
        this.activeModal.close();
    }

    saveLocation() {
        // CA-3049: NOTE(IAS): NS -> "There is no limit to how many locations an interviewer can add."
        // const maxLocations = 10;

        // const currentActiveLocations = this.locations.filter((location) => location.isActive).length;
        // if (currentActiveLocations >= maxLocations) {
        //     const modalRef = this.modalService.open(AlertModal);
        //     modalRef.componentInstance.title = "Cannot add or edit location";
        //     modalRef.componentInstance.body = `Cannot add location. You cannot have more than ${maxLocations} active locations.`;
        //     return;
        // }
        // Saving new location
        // console.log(`Active count at save: ${this.activeLocationCount}`);
        if (this.location?.id == -1) {
            this.interviewerService
                .insertInterviewerLocation(this.interviewerId,
                    this.location?.location.location.lat,
                    this.location?.location.location.lng,
                    this.form.getValue("locationName")!,
                    this.form.getValue("locationAvailability")!,
                    this.activeLocationCount < 8 ? true : false)
                    .subscribe({
                        next: () => {
                            //this.router.navigate(['/interviewer/details']);
                            this.activeModal.close(true);
                        },
                        error: (error) => {
                            let msg = error;
                            if (error instanceof HttpErrorResponse) { msg = `${error.statusText} (${error.status})`; }
                            console.error(msg);
                        }
                    });;
        } else {
            this.interviewerService
                .updateInterviewerLocation(this.interviewerId,
                    this.locationId!,
                    this.location?.location.location.lat!,
                    this.location?.location.location.lng!,
                    this.form.getValue("locationName")!,
                    this.form.getValue("locationAvailability")!,
                    this.location?.isActive ? true : false)
                    .subscribe({
                        next: () => {
                            //this.router.navigate(['/interviewer/details']);
                            this.activeModal.close(true);
                        },
                        error: (error) => {
                            let msg = error;
                            if (error instanceof HttpErrorResponse) { msg = `${error.statusText} (${error.status})`; }
                            console.error(msg);
                        }
                    });;
        }
    }

    onFormEvent(event: MetaFormUserEvent): void {
        switch (event.type) {
            case UserEventType.FormInitialised:
                break;
            case UserEventType.FormInvalid:
                this.isValid = false;
                break;
            case UserEventType.FormValid:
                this.isValid = true;
                break;
            case UserEventType.NavigationButtonClickedBack:
                break;
            case UserEventType.NavigationButtonClickedForward:
                break;
            case UserEventType.FormSubmit:
                break;
        }
    }
    changeSubscription?: Subscription;

    form!: MetaForm
    title = 'Edit Location';

    userId = -1;
    isLoading = false;

    isValid = false;
    hasPin = false;
    isProcessing = false;
    changesMade = false;

    emailAddress?: string;
    phone?: string;

    showSearchBox: boolean = false;
    isLocationLoaded: boolean = false;

    locations: LocationDetails[] = [];
}