<div class="upcoming-container" *ngIf="!isLoading; else still_loading">
    <app-upcoming-interviews-next *ngIf="showNextOverhead" [interviewerId]="interviewerId"></app-upcoming-interviews-next>
    <app-upcoming-interviews-week *ngIf="showThisWeek" [filterCountry]="filterCountry" [next]="next" [thisWeek]="thisWeek" [interviewerId]="interviewerId" [showTable]="showTable" [filter]="filter" [compact]="compact" [showThisWeek]="showThisWeek" [showTime]="showTime"></app-upcoming-interviews-week>
</div>
<ng-template #still_loading>
    <div class="loading">
        <h3 *ngIf="title">{{title}}</h3>
        <div class="load_container">
            <img src="img/stopwatch.svg" class="bl">
        </div>
    </div>
</ng-template>