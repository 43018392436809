<div class="modal-detail">
    <div class="modal-header">
        <h2>Upcoming Interviews</h2>
        <span class="dismiss" (click)="closeModal()"><i class="far fa-times-circle"></i></span>
    </div>
    <div class="modal-body">
        <div class="table-container">
            <app-upcoming-interviews-table [compact]="compact" [showInterviewer]="showInterviewer" [showTime]="showTime" [thisWeek]="thisWeek" [filter]="filter"></app-upcoming-interviews-table>
        </div>
        <div class="buttons">
            <button type="button" class="btn btn-danger btn-wide" (click)="closeModal()">Close</button>
        </div>
    </div>
</div>