import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { InterviewCalendarItem, InterviewSlot, InterviewSlotResponse, InterviewerService, UpdatedSlot } from './interviewer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LxWeek, Month } from '@aifs-shared/calendar/month';
import { Observable, Subject } from 'rxjs';
import { Change, Day, SelectedTimeSlot, TimeSlot } from '@aifs-shared/calendar/day';
import { DateTime } from 'luxon';
import { calendar_item } from './interviewer-calendar.component';
import { CalendarItem } from '@aifs-shared/calendar/calendar-item';
import { MonthChangeEvent, WeekSelectedEvent } from '@aifs-shared/calendar/month.component';
import { AlertModal, ConfirmationModal } from '@aifs-shared/modals';
import { InterviewerChangedItem, InterviewerDetailChanged } from './interviewer-detail-changed';
import { PortalUserPermission, PortalUserService } from '@aifs-shared/portal/portal-user-service';

@Component({
    templateUrl: './interviewer-availability-modal.component.html', 
    styleUrls: ['./style/interviewer-availability.component.scss',
                './style/interviewer-availability-modal.component.scss']
})
export class InterviewerAvailabilityModalComponent implements OnInit {
    @Input() interviewerId: number = 0;

    constructor(
        private modalService: NgbModal,
        private userService: PortalUserService,
        private interviewer: InterviewerService) {
        this.calendarDate = new Date(Date.now());
        this.monthCalculator = Month.calculate(this.calendarDate.getMonth(), this.calendarDate.getFullYear());
        this.canEdit = this.userService.hasPermission(PortalUserPermission.EditInterviewer);
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.loadInterviewerAvailability()
            .subscribe({
                next: (finished: boolean) => {
                    this.isLoading = false;

                    this.selectedWeek = this.monthCalculator.weeks[this.monthCalculator.activeWeek]
                    // console.log(`Selected Week: ${JSON.stringify(this.selectedWeek, null, 2)}`);
                    this.newWeekSelected(new WeekSelectedEvent(this.selectedWeek));
                },
                error: (error) => {
                    this.isLoading = false;
                }
            });
    }

    wantsToClose = false;

    closeModal() {
        if (this.changesMade) {
            if (this.displayMode === 'G') {
                const modalRef = this.modalService.open(ConfirmationModal);
                modalRef.componentInstance.title = 'Discard Changes';
                modalRef.componentInstance.body = `Are you sure you want to discard your changes?`;
                modalRef.result.then((result: boolean) => {
                    if(result) this.modalService.dismissAll();
                }).catch((err: any) => { });
            } else {
                this.cancelChangeAvailability(true);
            }
        } else {
            this.modalService.dismissAll();
        }
    }

    changeWeek(direction: number): void {
        const newWeek = this.activeWeek + direction;
        // console.log(`Want to update week from ${this.activeWeek} to ${newWeek}`);

        if (newWeek <= this.monthCalculator.weeks.length - 1 && newWeek > -1) {
            this.newWeekSelected(new WeekSelectedEvent(this.monthCalculator.weeks[newWeek]));
        } else {
            var week = 0;
            const firstDayShown = this.monthCalculator.weeks[this.activeWeek].days[0].day;
            // console.log(`First day currently showing is ${firstDayShown}`);
            if (direction < 0) {
                this.pauseUpdate = true;
                this.previousMonth();
                var week = this.monthCalculator.weeks.length - 1;
            }
            if (direction > 0) {
                this.pauseUpdate = true;
                this.nextMonth();
                var week = 0;
            }

            if (this.monthCalculator.weeks[week].days[0].day === firstDayShown) {
                // console.log(`Have already seen this week, skip it`);
                week += direction;
            }

            this.pauseUpdate = false;
            this.newWeekSelected(new WeekSelectedEvent(this.monthCalculator.weeks[week]));
        }
    }

    previousMonth() {
        var mce = new MonthChangeEvent(this.monthCalculator.previous, this.monthCalculator.previousYear);
        mce.useLastWeek = true;
        this.newMonthSelected(mce);
    }

    nextMonth() {
        // console.log(`Current: ${this.month.activeWeek}, ${this.selectedWeek?.getTitle()} next: ${this.month.next}`);
        this.newMonthSelected(new MonthChangeEvent(this.monthCalculator.next, this.monthCalculator.nextYear));
    }

    newMonthSelected(event: MonthChangeEvent) {
        const month = Month.calculate(event.newMonth, event.newYear);
        if (event.useLastWeek) {
            // console.log(`new month desired! ${event.newMonth}, ${event.newYear}`);
            // console.log(`use last week ${month.weeks.length - 1}`);
            this.activeWeek = month.weeks.length - 1;
            // console.log(`active week is: ${month.weeks[this.activeWeek].getTitle()}`);
        } else {
            // console.log(`use first week ${0}`);
            this.activeWeek = 0;
        }
        month.activeWeek = this.activeWeek;
        // Set this last as it triggers an OnChanges event in the calendar
        this.monthCalculator = month;

        if (this.pauseUpdate) return;
        this.generateTimeSlotsAndDays();
    }

    pauseUpdate = false;

    calendarItemSelected(ts: SelectedTimeSlot) {
        // console.log(`Selected: ${ts.day.name} ${JSON.stringify(ts.timeSlot)}`);
        
        if (!this.canEdit) return; // No permission!

        if (!ts.timeSlot!.isAvailable) {
            // console.warn(`Slot is not available`);
            return;
        }

        const exclusionDate = this.exclusionDateFrom;
        if (!ts.timeSlot!.isBooked) {
            let isLocked = !ts.timeSlot!.isLocked;
            const week = this.monthCalculator.weeks[this.activeWeek];
            const date = week.days[0].date.plus({ days: ts.day.dayOfWeek - 1 });

            // console.log(`Date is: ${date}`);
            // if (date < DateTime.now()) console.log(`In the past`);

            // Prevent selections past the exclusion date
            const past = exclusionDate && date >= exclusionDate || date < DateTime.now();
            if (past) {
                // console.warn(`past the exclusionDate or in the past`);
                return;
            }

            // console.log(`Before`, this.changedSlots);
            const exists = this.changedSlots.findIndex(cs => cs.timeSlot === ts.timeSlot!.name && cs.date.toISODate() === date.toISODate());
            if (exists >= 0) {
                const slot = this.changedSlots[exists];
                if (slot) {
                    // if (slot.originalValue != isLocked) {
                    //     // The original value has changed
                    //     // console.log(`Original value changed from ${slot.originalValue} to ${isLocked}`);
                    // }
                    // console.log(`Change to existing : ${JSON.stringify(slot)}`);
                    slot.newValue = isLocked;
                }
            } else {
                //console.log(`Changed a slot: ${ts.timeSlot!.name}`);
                this.changedSlots.push(new Change(date, ts.timeSlot!.name, isLocked, ts.timeSlot!.isLocked));
            }

            ts.timeSlot!.isLocked = isLocked;

            //const updated = this.changedSlots.filter(cs => cs.originalValue != cs.newValue);
            //this.changedSlots = updated.slice();
            // console.log(`Changed`, this.changedSlots);

            // Check for duplications
            this.changesMade = this.changedSlots.length > 0;
        }
    }

    loadInterviewerAvailability(): Observable<boolean> {
        const s = new Subject<boolean>();

        const iv = this.interviewerId;
        this.interviewer
            .getInterviewSlotsForInterviewer(iv)
            .subscribe({
                next: (result: InterviewSlotResponse) => {
                    const slots = result.slots;
                    if (result.exclusionFrom) {
                        this.exclusionDate = DateTime.fromISO(result.exclusionFrom);
                        // console.log(`Got exclusion date: ${this.exclusionDate}`);
                    }
                    
                    if (slots.length != 0) {
                        this.readSlots = slots;
                        this.interviewerTimezone = result.slots[0].tzTimezoneCode;
                    }

                    if (result.exclusionFrom) {
                        this.exclusionDateFrom = DateTime.fromISO(result.exclusionFrom);
                        // console.log(`Got exclusion date: ${this.exclusionDateFrom}`);
                    }

                    // var exclusions = slots.filter(s => s.isAvailable == false);

                    // if (exclusions) {
                    //     //console.log(`Got exclusion slots: ${JSON.stringify(exclusions)}`);
                    //     // exclusions.forEach(s => {
                    //     //     this.changedSlots.push(new Change(DateTime.fromISO(s.day), s.exclusionTime, s.isLocked, s.isLocked));
                    //     // })
                    // }

                    s.next(true);
                },
                error: (error) => {
                    console.error(error);
                    s.error(error);
                }
            });

        return s;
    }

    newWeekSelected(event: WeekSelectedEvent) {
        // console.log(`New date selected: ${event.week.getTitle()}`);
        this.activeWeek = event.week.id;
        this.monthCalculator.activeWeek = this.activeWeek;
        this.generateTimeSlotsAndDays();
    }

    timeSlotSelected(selection: SelectedTimeSlot) {

        if (!this.canEdit) return; // No permission!

        this.selectedDay = selection.day;
        this.selectedTimeSlot = selection.timeSlot;
        //const interviewerId = this.interviewerId;
        if (this.selectedTimeSlot) {
            // console.log(`Changes were made: `);
            const slot = this.selectedTimeSlot;
            this.updateSlot(slot.id, slot.isAvailable);
        } else {
            const day = this.selectedDay;
            if (day) {
                day.slots.forEach(sl => {
                    this.updateSlot(sl.id, sl.isAvailable);
                });
            }
        }
    }

    changeAvailability() {
        this.displayMode = 'E';
    }

    saveChanges(): void {
        const iv = this.interviewerId;
        this.isProcessing = true;

        this.interviewer
            .updateInterviewSlotExclusions(iv, this.changedSlots)
            .subscribe({
                next: (r) => {
                    this.changedSlots = [];
                    this.changesMade = false;

                    this.showModal("General Availability", "Your changes have been saved.");

                    this.loadInterviewerAvailability()
                        .subscribe({
                            next: (finished: boolean) => {
                                this.generateTimeSlotsAndDays();
                                this.isProcessing = false;
                            },
                            error: (error) => {
                                this.isProcessing = false;
                            }
                        });
                    
                    this.interviewer.notifyInterviewerDetailChanged(new InterviewerDetailChanged(InterviewerChangedItem.Availability, this.interviewerId));
                },
                error: (error) => {
                    this.isProcessing = false;
                    console.error(error);
                }
            })
    }

    /**
     * @description Using the general availability slots, build the week calendar,
     * starting with the current week
     * @param generalAvailabilitySlots - InterviewSlot array from server
     */
    private generateTimeSlotsAndDays(actualSlots?: CalendarItem[]) {
        this.days = [];
        var excl: calendar_item[] = [];

        const week = this.monthCalculator.weeks[this.activeWeek];
        //console.log(`Got active week: ${week.getTitle()}, firstDate: ${week.getFirstDate()}`);

        this.isRefreshing = true;
        const iv = this.interviewerId;
        this.interviewer
            .getInterviewsForInterviewer(iv, week.getFirstDate(), week.getLastDateForRange())
            .subscribe({
                next: (res: InterviewCalendarItem[]) => {
                    // console.log(`Got: ${JSON.stringify(res)}`);

                    res.forEach(ic => {
                        excl.push(new calendar_item(DateTime.fromISO(ic.startDate), DateTime.fromISO(ic.endDate), ic.entryType, ic.name));
                    });

                    // INT-39
                    const tooEarly = DateTime.now().plus({ hours: 48 });
                    const tooLate = DateTime.now().plus({ days: 17 }); // Matches usp_sel_interview_availability_for_application

                    if (this.readSlots && this.readSlots.length > 0) {
                        for (let day of week.days) {
                            const daySlots = this.readSlots.filter(s => s.dayOfWeek === day.dayOfWeek);
                            if (daySlots) {
                                const d = new Day(day.date.weekdayShort!, true, day.dayOfWeek);
                                daySlots.forEach(ds => {
                                    var ts = new TimeSlot(ds.availabilitySlotId, ds.startTime, ds.endTime, ds.isAvailable);

                                    const slotDate1 = DateTime.fromISO(`${day.date.toISODate()}T${ds.startTime}`);
                                    const slotDate2 = DateTime.fromISO(`${day.date.toISODate()}T${ds.endTime}`).minus(60000);

                                    const ex: calendar_item[] = excl.filter(e => e.contains(slotDate1, slotDate2));
                                    if (ex.length > 0) {
                                        ex.forEach(e => {
                                            if (e.type === 1) {
                                                ts.isBooked = true;
                                                ts.interviewName = e.name;
                                            }
                                            if (e.type === 2) ts.isLocked = true;
                                        });
                                    }

                                    if (this.exclusionDateFrom && slotDate1 > this.exclusionDateFrom) {
                                        ts.isLocked = true;
                                        ts.isApplicantUnselectable = true;
                                    }

                                    if (slotDate1 < tooEarly || slotDate2 > tooLate) {
                                        ts.isApplicantUnselectable = true;
                                    }

                                    const exists = this.changedSlots.findIndex(cs => cs.timeSlot === ts.name && cs.date.toISODate() === day.date.toISODate());
                                    if (exists > -1) {
                                        //console.log(`There's a change here ${day.date.toISODate()} ${ts.name}`);
                                        ts.isLocked = this.changedSlots[exists].newValue;
                                    }


                                    d.slots.push(ts);
                                });
                                this.days.push(d);                                
                            }
                            else {
                                console.warn(`No daySlot found for ${day.dayOfWeek}`);
                            }
                            this.originalDays = JSON.stringify(this.days.slice());
                        }
                    } else {
                        console.warn(`No readSlots?`, this.readSlots);
                    }

                    this.weekName = this.monthCalculator.weeks[this.activeWeek].getTitle();

                    this.isRefreshing = false;
                },
                error: (error) => {
                    console.error(error);

                    this.isRefreshing = false;
                }
            });

    }

    private updateSlot(slotId: number, isAvailable: boolean) {
        // console.log(`Updating ${slotId}. Slots in list: ${this.changedSlotsEditMode.length}`);

        const slot = this.readSlots.find(sl => sl.availabilitySlotId == slotId);
        if (!slot) {
            console.error(`Missing slot: ${slotId}`);
        } else {
            const alreadyUpdated = this.changedSlotsEditMode.find(cs => cs.availabilitySlotId == slotId);
            if (!alreadyUpdated) {
                // console.log(`New slot`);
                const ts = new UpdatedSlot(slotId, isAvailable);
                this.changedSlotsEditMode.push(ts);
            } else {
                // console.log(`Updating existing: ${JSON.stringify(alreadyUpdated)}`);
                alreadyUpdated.isAvailable = isAvailable;
            }
        }

        // console.log(`Slots: ${JSON.stringify(this.changedSlotsGeneral, null, 2)}`);
        this.changesMadeEditMode = true;
        // console.log(`Slots in list: ${this.changedSlotsEditMode.length}`);

    }

    cancelChangeAvailability(closingModal = false) {
        // Check for changes
        if (this.changesMadeEditMode) {
            const modalRef = this.modalService.open(ConfirmationModal);
            modalRef.componentInstance.title = 'Discard Changes';
            modalRef.componentInstance.body = `Are you sure you want to discard your changes?`;
            modalRef.result.then((result: boolean) => {
                if (result) {
                    this.changedSlotsEditMode = [];
                    // console.log(`Days: ${this.days[5].slots, null, 2}`, this.days[5].slots);
                    const reset: Day[] = JSON.parse(this.originalDays) as Day[];
                    // console.log(`Original Days: `, reset[5].slots);
                    this.days = reset;
                    this.changesMadeEditMode = false;
                    this.displayMode = 'G';
                } else {
                    if(closingModal) this.modalService.dismissAll();
                }
            }).catch((err: any) => { });
        } else {
            if (closingModal) {
                this.modalService.dismissAll();
            } else {
                this.displayMode = 'G';
            }
        }
    }

    saveChangedGeneral() {
        this.isProcessing = true;
        const interviewerId = this.interviewerId;

        this.saveChangedGeneralSlots(interviewerId);
    }

    private saveChangedGeneralSlots(interviewerId: number): void {
        // Can't change exclusion date from here, but it must be
        // passed back to the server during the update, else
        // it will be cleared (CA-3058)
        let exclusionDateFrom = this.exclusionDate ? this.exclusionDate!.toISODate() : '';
        let interviewerTimezone = this.interviewerTimezone;
        this.interviewer
            .updateInterviewSlotAvailability(interviewerId, this.changedSlotsEditMode, interviewerTimezone!, exclusionDateFrom!)
            .subscribe({
                next: (result: InterviewSlot) => {
                    // Not sure what we want to do with this...
                    this.changesMadeEditMode = false;
                    this.changedSlotsEditMode = [];
                    this.isProcessing = false;
                    // this.changedExclusionDate = false;
                    this.showModal("General Availability", "Your changes have been saved.");
                    this.interviewer.notifyInterviewerDetailChanged(new InterviewerDetailChanged(InterviewerChangedItem.Availability, this.interviewerId));
                },
                error: (error: any) => {
                    console.error(error);
                    this.isProcessing = false;
                }
            });
    }

    private showModal(title: string, message: string) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = message;

        modalRef.result.then(
            result => {
                if (result) {

                }
            }).catch((err: any) => { });
    }

    canEdit = false;

    isLoading = true;
    isRefreshing = false;
    isProcessing = false;
    changesMade = false;
    changesMadeEditMode = false;

    activeWeek = 0;
    weekName = '';

    changedSlots: Change[] = [];
    changedSlotsEditMode: UpdatedSlot[] = [];

    readSlots: InterviewSlot[] = [];
    exclusionDateFrom?: DateTime;

    originalDays: string = '';
    days: Day[] = [];
    monthCalculator: Month = new Month();
    calendarDate: Date = new Date(Date.now());
    selectedWeek?: LxWeek;

    displayMode = 'G'; // General availability (E - Edit availability)

    selectedDay?: Day;
    selectedTimeSlot?: TimeSlot;

    exclusionDate?: DateTime;
    interviewerTimezone?: string;
}

