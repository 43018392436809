import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApplicantDetailChanged, ChangedItem } from '../applicant/applicant-detail-changed';
import { ApplicantService } from '../applicant/applicant.service';
import { ApplicantQueueItem, InterviewerQueueResponse, InterviewerQueueType, InterviewerService } from './interviewer.service';


@Component({
    selector: 'app-interview-report-rts',
    templateUrl: './interview-reports-ready-to-submit.component.html',
    styleUrls: ['./style/applicant-view-styles.scss']
})
export class InterviewReportsReadyToSubmitComponent implements OnInit, OnDestroy {
    @Input() interviewerId: number = 0;

    constructor(
        private router: Router,
        private appService: ApplicantService,
        private userService: PortalUserService,
        private interviewer: InterviewerService) {
    }

    ngOnInit(): void {
        this.getIncompleteInterviewReports();
        this.changeSubscription = this.appService.applicantDetailsChanged$.subscribe({
            next: (changeItem: ApplicantDetailChanged) => {
                if (changeItem.changedItem === ChangedItem.Interview || changeItem.changedItem === ChangedItem.ApplicationSubmittedToOffice) {
                    const inList = this.applicants.findIndex(a => a.applicationId === changeItem.applicationId);
                    if(inList > -1) this.getIncompleteInterviewReports();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.changeSubscription.unsubscribe();
    }

    private getIncompleteInterviewReports(): void {
        this.noData = true;

        this.isLoading = true;

        if(this.userService.hasStaffRole()){
            this.loadDetailsForStaff();
        }
        else if(this.userService.hasAgentRole()){
            this.loadDetailsForAgent();
        }
        else if(this.userService.hasInterviewerRole() && this.interviewerId > 0){
            this.loadDetailsForInterviewer();
        }
    }

    loadDetailsForInterviewer() {
        const intId = this.interviewerId === 0 ? this.userService.getInterviewerId() : this.interviewerId;
        this.interviewer
            .getApplicantByQueueForInterviewer(intId, InterviewerQueueType.ReadyToSubmit)
            .subscribe({
                next: (result: InterviewerQueueResponse) => {
                    this.populateData(result);
                },
                error: (error: any) => { console.error(error); this.isLoading = false; }
            });
    }

    loadDetailsForAgent() {
        const agentId = this.userService.getAgentId();
        this.showInterviewer = true;
        this.interviewer.getApplicantByQueueForAgent(agentId, InterviewerQueueType.ReadyToSubmit)
            .subscribe(
                {
                    next: (result: InterviewerQueueResponse) => {
                        this.populateData(result);
                    },
                    error: (error) => { console.error(error); this.isLoading = false; }
                });
    }

    loadDetailsForStaff() {
        const staffId = this.userService.getStaffId();
        this.showInterviewer = true;
        this.interviewer.getApplicantByQueueForStaff(staffId, InterviewerQueueType.ReadyToSubmit)
            .subscribe(
                {
                    next: (result: InterviewerQueueResponse) => {
                        this.populateData(result);
                    },
                    error: (error) => { console.error(error); this.isLoading = false; }
                });
    }

    private populateData(result: InterviewerQueueResponse) {
        if (result.details) {
            this.applicants = result.details.items;
            this.count = this.applicants.length;
            if (this.count > 0) {
                this.noData = false;
            }
            this.isLoading = false;
        }
    }

    openApplicant(a: ApplicantQueueItem): void {
        const link = `/applicant/${a.applicantId}/${a.applicationId}`;
        // console.log(`Showing applicant ${applicantId}`);
        this.router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    useInterviewerId: number = 0;
    noData = false;

    applicants: ApplicantQueueItem[] = [];
    count = 0;

    isLoading = true;
    showInterviewer = false;
    changeSubscription!: Subscription;
}
