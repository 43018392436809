<div class="upcoming-container" [class.clickable]="hasForthcoming">
    <div *ngIf="hasForthcoming" [class.upcoming-detail-card]="!showTable" (click)="showTableDialog()">
        <h3 *ngIf="title">{{title}} <span *ngIf="showThisWeek && count > 0">{{count}}</span></h3>
        <div *ngIf="showTable; else showSummary">
            <app-upcoming-interviews-table *ngIf="thisWeek" [filterCountry]="filterCountry" [compact]="compact" [showInterviewer]="showInterviewer" [showTime]="showTime" [thisWeek]="thisWeek" [filter]="filter"></app-upcoming-interviews-table>
            <div *ngIf="showThisWeek && (!hasForthcoming && !hasNext)" class="this-week">
                <h3 *ngIf="title">{{title}}</h3>
                <p>{{noInterviews}}</p>
            </div>
        </div>
        <ng-template #showSummary>
            <p *ngIf="next">Next interview {{next.date}}, {{next.hour}}</p>
        </ng-template>
    </div>
</div>