<div [class.clickable]="!showTable" (click)="showTableDialog()">
    <h3>Incomplete Reports <span *ngIf="count > 0">{{count}}</span></h3>
    <div class="incomplete" *ngIf="!isLoading; else still_loading">
        <div *ngIf="!showTable && submitTimeDays">
            <p>Average time to submit: {{submitTimeDays}}</p>
        </div>
        <table *ngIf="!noData && showTable" class="table table-striped table-fixed">
            <thead *ngIf="showInterviewer" class="sortable-header">
                <tr>
                    <th [ngClass]="sortType('name')" class="appname" sortableiir="name" (sort)="onSort($event)">Name</th>
                    <th [ngClass]="sortType('interviewerName')" sortableiir="interviewerName" (sort)="onSort($event)">Interviewer</th>
                    <th [ngClass]="sortType('interviewDateTime')" class="date" sortableiir="interviewDateTime" (sort)="onSort($event)">Date</th>
                    <th class="state">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of softedFilteredList" class="applicant" (click)="openApplicant(a)">
                    <td class="appname">
                        <app-round-image class="profile-image" [url]="a.profile" [useDefaultMissingImage]="true" [width]="30" [height]="30"></app-round-image>
                        <span *ngIf="a.status > 0" container="body" ngbPopover="{{a.statusInfo}}" popoverClass="slot-popover"
                            triggers="mouseenter:mouseleave" popoverTitle="Note"><i class="warning fa-solid fa-circle-exclamation"></i></span>
                        {{a.name}}
                    </td>
                    <td class="interviewer" *ngIf="showInterviewer">{{a.interviewerName}}</td>
                    <td class="date">{{a.date}}</td>
                    <td class="state">{{a.userState}}</td>
                </tr>
            </tbody>
        </table>
        <!-- <div *ngIf="noData"><p>No incomplete interview reports.</p></div> -->
    </div>
    <ng-template #still_loading>
        <div class="loading"><div class="load_container">
            <img src="img/stopwatch.svg" class="bl">
        </div></div>
    </ng-template>
</div>