import { PortalUserPermission, PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicantService } from '../applicant/applicant.service';
import { AgencyInterviewerDetailsResponse, AgentInterviewerDetails, ApplicantQueueItem, InterviewerService } from './interviewer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal, ConfirmationModal } from '@aifs-shared/modals';


@Component({
    templateUrl: './incomplete-interview-reports-modal.component.html',
    styleUrls: ['./style/applicant-view-styles.scss', '../shared/modals/style/modal-styles-generic.scss']
})
export class IncompleteInterviewReportsModalComponent implements OnInit {
    @Input() interviewerId = 0;
    @Input() applicants: ApplicantQueueItem[] = [];
    @Input() showInterviewer = false;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private appService: ApplicantService,
        private userService: PortalUserService,
        private interviewerService: InterviewerService) {
        this.canShowReportLimit = this.userService.hasPermission(PortalUserPermission.EditInterviewer);
    }

    ngOnInit(): void {
        if (!this.canShowReportLimit || this.interviewerId == 0) return;

        this.interviewerService
            .getInterviewerDetails(this.interviewerId)
            .subscribe({
                next: (r: AgencyInterviewerDetailsResponse) => {
                    if (r.details) {
                        this.interviewer = r.details;
                        this.overdueReportLimit = this.interviewer.overdueReportLimit;
                        this.originalLimit = this.overdueReportLimit;
                    }
                }
            })
    }

    closeModal() {
        if (this.overdueReportLimit !== this.originalLimit) {
            const modalRef = this.modalService.open(ConfirmationModal);
            modalRef.componentInstance.title = 'Discard Changes';
            modalRef.componentInstance.body = `<p>You have changed the overdue report limit for this interviewer. Do you want to close without saving?</p>`;

            modalRef.result.then((result: boolean) => {
                if (result) {
                    // Actually send the email
                    this.modalService.dismissAll();
                }
            }).catch((err: any) => { });
        } else {
            this.modalService.dismissAll();
        }
    }

    openApplicant(a: ApplicantQueueItem): void {
        const link = `/applicant/${a.applicantId}/${a.applicationId}`;
        // console.log(`Showing applicant ${applicantId}`);
        this.router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    decreaseLimit(): void {
        if (this.overdueReportLimit > 0) this.overdueReportLimit--;
    }

    increaseLimit(): void {
        if (this.overdueReportLimit < 99) this.overdueReportLimit++;
    }

    saveChangedLimit(): void {
        //console.log(`Update interviewer ${this.interviewerId} with new limit ${this.overdueReportLimit}`);
        const userId = this.userService.getUser().userId;
        this.isProcessing = true;
        
        this.interviewerService
            .updateInterviewerLimits(userId, this.interviewerId, -1, this.overdueReportLimit)
            .subscribe({
                next: (result: boolean) => {
                    this.isProcessing = false;
                    if (result) {
                        this.showAlert(`Save`, `Interviewer report limits updated successfully.`);
                        this.originalLimit = this.overdueReportLimit;
                    }
                },
                error: (error: any) => {
                    this.isProcessing = false;
                    console.error(error);
                    this.showAlert(`Save`, `There was an error updating the database:`, error);
                }
            }
        )
    }

    showAlert(title: string, explain: string, error: string | undefined = undefined) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = explain;
        modalRef.componentInstance.cssClass = error === undefined ? "info" : "danger";
        if (error)
            modalRef.componentInstance.error = error;

        modalRef.result.then(result => {
            if (result) {
            }
        }).catch((err: any) => { });
    }

    interviewer?: AgentInterviewerDetails;
    canShowReportLimit = false;
    overdueReportLimit = 0;
    originalLimit = 0;

    isProcessing = false;
}