<div class="modal-content">
    <div class="modal-header">
        <h2 class="modal-title">{{title}}</h2>
        <span class="dismiss" (click)="closeModal()"><i class="far fa-times-circle"></i></span>
    </div>
    <div class="modal-body">
        <div class="interviewer-details-location">
            <div *ngIf="showSearchBox">
                <div class="mf-caption">
                <label class="caption">Address</label>
                </div>
                <app-map (currentLocationChanged)="currentLocationChanged($event)" [showSearchTextbox]="showSearchBox"
                    [showLocation]="false" [mapMarkers]="[location!.location]" [panToLocation]="location!.location"></app-map>
            </div>
            <div *ngIf="!showSearchBox && location?.location">
                <app-map [showSearchTextbox]="false" [showLocation]="false" [mapMarkers]="[location!.location]"
                    [panToLocation]="location!.location"  [selectedMarker]="location!.location"></app-map>
            </div>

            <lib-metaform-display [form]="form" [showButtons]="false" (formEvent)="onFormEvent($event)"></lib-metaform-display>

        </div>
        <div class="actions">
            <button type="button" class="btn btn-success" [disabled]="!(hasPin && isValid && changesMade) || isProcessing"
                (click)="saveLocation()">Save <img *ngIf="isProcessing" src="img/white-loader.gif"></button>
<!--
            <button type="button" class="btn btn-danger" style="margin-left: 0.5rem"
                (click)="cancel()">Cancel</button>
-->
        </div>
    </div>
</div>
