<div *ngIf="interviewer" class="sidebar-card">
    <div class="actions" *ngIf="showActions">
        <button type="button" class="btn btn-primary btn-wide" (click)="sendMessage()">
            <i class="far fa-envelope"></i> Message {{interviewer.firstName}}
        </button>
        <button *ngIf="!azureLoginSet() && canCreateAzureLogins" type="button" class="btn btn-primary btn-wide" (click)="createAzureLogin()">
            <i class="fa-solid fa-key"></i> Create Login
        </button>
    </div>
    <div class="key-data">
        <span class="d"><i class="one-wide far fa-envelope"></i> <span class="email">&nbsp;{{emailAddress}}</span></span>
        <span *ngIf="azureLoginSet()" class="d" >
            <i class="one-wide fa-solid fa-key"></i><span class="email">&nbsp;{{azureLogin}}</span>
        </span>
        <span class="d phone"><i class="one-wide fas fa-mobile-alt"></i>&nbsp;{{phone}}</span>
        <br>
        <span class="d"><i class="one-wide far fa-flag"></i> {{country}}</span>

        <span class="d i" *ngIf="region"><i class="one-wide fas fa-map-marker-alt"></i> {{region}}</span>
        <span class="d"><i class="one-wide far fa-building"></i> {{agency}}</span>
        <br>
        <span class="d" *ngIf="applicantLimit"><i class="one-wide fa-solid fa-users-between-lines"></i> {{applicantLimit}}</span>
        <span *ngIf="lastLoginDays" class="d"><i class="one-wide fas fa-sign-in-alt"></i> {{lastLoginDays}}</span>
    </div>

</div>
