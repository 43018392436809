import { Component, Input, OnInit } from '@angular/core';
import { InterviewDetails, InterviewerService, UpcomingInterviews } from './interviewer.service';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-upcoming-interviews-next',
    templateUrl: './upcoming-interview-next.component.html',
    styleUrls: ['./style/upcoming-interviews.component.scss']
})
export class UpcomingInterviewNextComponent implements OnInit {
    @Input() interviewerId = 0;

    constructor(
        private router: Router,
        private interviewerService: InterviewerService,
        private userService: PortalUserService) {
    }

    ngOnInit(): void {
        const intId = this.interviewerId === 0 ? this.userService.getInterviewerId() : this.interviewerId;

        if(this.userService.hasStaffRole()){
            this.loadDetailsForStaff();
        }
        else if(this.userService.hasAgentRole()){
            this.loadDetailsForAgent();
        }
        else if(this.userService.hasInterviewerRole() && intId > 0){
            this.loadDetailsForInterviewer(intId);
        }
    }

    loadDetailsForStaff() {
        const staffId = this.userService.getStaffId();

        this.interviewerService
            .getUpcomingInterviewsForStaff(staffId)
            .subscribe(
                {
                    next: (result: UpcomingInterviews) => {
                        this.populateData(result);
                    },
                    error: (error) => {
                        this.receivedError(error);
                    }
                });
    }

    loadDetailsForAgent() {
        const agentId = this.userService.getAgentId();

        this.interviewerService
            .getUpcomingInterviewsForAgent(agentId)
            .subscribe(
                {
                    next: (result: UpcomingInterviews) => {
                        this.populateData(result);
                    },
                    error: (error) => {
                        this.receivedError(error);
                    }
                });
    }

    loadDetailsForInterviewer(interviewerId: number) {
        this.interviewerService
            .getUpcomingInterviewsForInterviewer(interviewerId)
            .subscribe(
                {
                    next: (result: UpcomingInterviews) => {
                        this.populateData(result);
                    },
                    error: (error) => {
                        this.receivedError(error);
                    }
                });
    }

    private populateData(result: UpcomingInterviews) {
        if (result) {
            if (result.next) {
                this.hasNext = true;
                this.next = result.next;
            } else {
                this.hasNext = false;
            }
        } else {
            this.hasNext = false;
        }
    }

    private receivedError(error: any) {
        this.hasNext = false;
    }

    openApplicant(next: InterviewDetails) {
        //console.log(`Open ${next.applicantId}/${next.applicationId}`);
        const link = `/applicant/${next.applicantId}/${next.applicationId}`;
        // console.log(`Showing applicant ${applicantId}`);
        this.router.navigate([]).then((result:any) => { window.open(link, '_blank'); });        
    }

    hasNext = true;
    next?: InterviewDetails;
}