import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { InterviewerService, OnlineInterviewSlotAvailability } from './interviewer.service';
import { Day, Slot } from './summary-calendar.component';
import { MFOptionValue, MFOptions, MetaForm } from '@aifs-shared/ngx-metaform/metaform';
import { MFValueChange } from '@aifs-shared/ngx-metaform/metaform-data';
import { MetaFormUserEvent, UserEventType } from '@aifs-shared/ngx-metaform/ui/metaform-display.component';
import { Subscription } from 'rxjs';
import { StaffCountry } from '@aifs-shared/user/user';
import { MetaFormService } from '@aifs-shared/ngx-metaform/metaform.service';
import { MetaFormDrawType } from '@aifs-shared/ngx-metaform/metaform-enums';

@Component({
    selector: 'app-interviewer-summary',
    templateUrl: './interviewer-summary.component.html',
    styleUrls: ['./style/interviewer-summary.component.scss']
})

export class InterviewerSummaryComponent implements OnInit {
    loginDisplay = false;
    loggedIn = false;
    userRole = '';
    isAgent = false;
    isInterviewer = false;
    filterForm?: MetaForm;
    filterCountry = '';
    filterFormChanges?: Subscription;
    showFilter = false;

    constructor(
        private title: Title,
        private userService: PortalUserService,
        private interviewerService: InterviewerService,
        private metaFormBuilder: MetaFormService
    ) { }

    ngOnInit(): void {
        this.title.setTitle('Camp America Management Portal');
        if(this.userService.hasStaffRole()) {
            this.createFilterForm();
        }
    }

    ngOnDestroy(): void {
        this.filterFormChanges?.unsubscribe();
    }

    createFilterForm(): void {
        this.userService
            .getStaffCountryList()
            .subscribe({
                next: (c: StaffCountry[]) => {
                    if (c.length > 0) {
                        this.filterForm = this.metaFormBuilder.createForm('Filter', '', MetaFormDrawType.EntireForm);
                        this.filterForm.addSection('Details');
                        this.filterForm.setValue('countryCode', this.filterCountry);
                        const countries: MFOptionValue[] = c.map(item => new MFOptionValue(item.code, item.name));
                        this.filterForm.addQuestion('q1')
                            .addOptionControl('countryCode', MFOptions.OptionFromList(countries, 'Show All', false))
                        this.filterFormChanges = this.filterForm!.change$
                            .subscribe((chg: MFValueChange) => {
                                //console.log(`Filter: ${chg.value}`);
                                this.filterCountry = chg.value;
                            });
                        
                    }
                    this.showFilter = c && c.length > 0;
                },
                error: (error: any) => {
                    console.error(error);
                }
            });
    }

    onFormEvent(event: MetaFormUserEvent): void {
        //do nothing
    }

    calendarSlotSelected(event: Slot): void {
        let agentId = 0;
        let staffId = 0;

        // Staff role should take precedence
        if (this.userService.hasStaffRole()) {
            staffId = this.userService.getStaffId();
        } else if (this.userService.hasAgentRole()) {
            agentId = this.userService.getAgentId();
        } else {
            console.error(`User does not have a staff or agent role`);
        }

        // console.log(`Selected slot: ${event.day.systemDate.toISODate()}, ${event.startTime}, dow: ${this.dayOfWeekToOrdinal(event.day.dayOfWeek)}`);
        this.interviewerService
            .getSlotDetailsForAgent(agentId, staffId, this.dayOfWeekToOrdinal(event.day.dayOfWeek), event.startTime, event.day.systemDate.toISODate()!, this.filterCountry)
            .subscribe({
                next: (response: OnlineInterviewSlotAvailability) => {
                    // console.log(`slot avail: ${JSON.stringify(response)}`);
                    this.selectedSlot = response.slotName;
                    this.available = response.available.slice();
                    this.unavailable = response.unavailable.slice();
                    this.hasSelected = true;
                },
                error: (error: any) => {
                    console.error(error);
                }
            });
    }

    dayOfWeekToOrdinal(dow: string): number {
        switch (dow.toLowerCase()) {
            case 'mon': return 1;
            case 'tue': return 2;
            case 'wed': return 3;
            case 'thu': return 4;
            case 'fri': return 5;
            case 'sat': return 6;
            case 'sun': return 7;
        }
        return 0;
    }

    hasSelected = false;
    selectedSlot = "";
    available: string[] = [];
    unavailable: string[] = [];
}
