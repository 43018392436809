<div class="modal-detail">
    <div class="modal-header">
        <h2>Incomplete Reports</h2>
        <span class="dismiss" (click)="closeModal()"><i class="far fa-times-circle"></i></span>
    </div>
    <div class="modal-body">
        <div *ngIf="canShowReportLimit" class="reportLimit">
            <span class="label">Overdue report limit:</span>
            <span class="edit">
                <span class="down" (click)="decreaseLimit()"><i class="fa-regular fa-square-minus"></i></span>
                <span class="value" [class.changed]="originalLimit != overdueReportLimit">{{overdueReportLimit}}</span>
                <span class="up" (click)="increaseLimit()"><i class="fa-regular fa-square-plus"></i></span>
                <button class="save" [disabled]="originalLimit == overdueReportLimit" (click)="saveChangedLimit()">
                    <i *ngIf="!isProcessing"class="fa-regular fa-floppy-disk"></i>
                    <i *ngIf="isProcessing"class="fa-regular fa-hourglass"></i>
                </button>
            </span>
        </div>
        <div class="table-container" *ngIf="applicants.length > 0">
            <table class="table table-striped table-fixed">
                <tbody>
                    <tr *ngFor="let a of applicants" class="applicant" (click)="openApplicant(a)">
                        <td>
                            <app-round-image class="profile-image" [url]="a.profile" [useDefaultMissingImage]="true" [width]="30" [height]="30"></app-round-image>
                            <span *ngIf="a.status > 0" container="body" ngbPopover="{{a.statusInfo}}" popoverClass="slot-popover"
                                triggers="mouseenter:mouseleave" popoverTitle="Note"><i class="warning fa-solid fa-circle-exclamation"></i></span>
                            {{a.name}}
                        </td>
                        <td class="interviewer" *ngIf="showInterviewer">{{a.interviewerName}}</td>
                        <td class="state">{{a.userState}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="buttons">
            <button type="button" class="btn btn-danger btn-wide" (click)="closeModal()">Close</button>
        </div>
    </div>
</div>