import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicantQueueItem, InterviewerQueueResponse, InterviewerQueueType, InterviewerService } from './interviewer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompletedInterviewsModalComponent } from './completed-interviews-modal.component';


@Component({
    selector: 'app-interview-complete',
    templateUrl: './completed-interviews.component.html',
    styleUrls: ['./style/applicant-view-styles.scss']
})
export class CompletedInterviewsComponent implements OnInit {
    @Input() interviewerId: number = 0;
    @Input() showTable = true;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private userService: PortalUserService,
        private interviewer: InterviewerService) {
    }

    ngOnInit(): void {
        if (this.interviewerId == 0) {
            this.useInterviewerId = this.userService.getInterviewerId();
        } else {
            this.useInterviewerId = this.interviewerId;
        }

        this.getCompletedInterviews();
    }

    private getCompletedInterviews(): void {
        this.noData = true;

        this.interviewer
            .getApplicantByQueueForInterviewer(this.useInterviewerId, InterviewerQueueType.CompletedInterviews)
            .subscribe({
                next: (result: InterviewerQueueResponse) => {
                    if (result.details) {
                        this.applicants = result.details.items;
                        this.count = this.applicants.length;
                        this.noData = (this.count === 0);
                        this.isLoading = false;
                    }
                 },
                error: (error: any) => { console.error(error); this.isLoading = false; }
            });

    }

    openApplicant(a: ApplicantQueueItem): void {
        const link = `/applicant/${a.applicantId}/${a.applicationId}`;
        // console.log(`Showing applicant ${applicantId}`);
        this.router.navigate([]).then(result => { window.open(link, '_blank'); }); 
    }

    showTableDialog(): void {
        if (this.showTable || this.count === 0) return;

        const modalRef = this.modalService.open(CompletedInterviewsModalComponent, { size: 'lg', windowClass: 'modal', scrollable: true });
        modalRef.componentInstance.applicants = this.applicants;
        modalRef.componentInstance.title = 'Completed Interviews';
        modalRef.result.then().catch((err: any) => { });
    }

    useInterviewerId: number = 0;
    noData = false;

    applicants: ApplicantQueueItem[] = [];
    count = 0;

    isLoading = true;
}