import { PortalUserPermission, PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgencyInterviewerDetailsResponse, AgentInterviewerDetails, InterviewerService } from './interviewer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserFormService } from '@aifs-shared/forms/user-form.service';
import { AlertModal } from '@aifs-shared/modals';
import { MetaForm } from '@aifs-shared/ngx-metaform/metaform';
import { MFValueChange } from '@aifs-shared/ngx-metaform/metaform-data';
import { MetaFormUserEvent, UserEventType } from '@aifs-shared/ngx-metaform/ui/metaform-display.component';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { InterviewerChangedItem, InterviewerDetailChanged } from './interviewer-detail-changed';

@Component({
    templateUrl: './interviewer-profile-bio-modal.component.html',
    styleUrls: ['./style/interviewer-profile-bio-modal.component.scss', '../shared/modals/style/modal-styles-generic.scss']
})
export class InterviewerProfileBioModalComponent implements OnInit, OnDestroy {
    @Input() interviewerId!: number;
    @Input() interviewer!: AgentInterviewerDetails;

    isOnlineInterviewer: boolean = true;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private userService: PortalUserService,
        private userForm: UserFormService,
        private interviewerService: InterviewerService) {

        this.isReadonly = !this.userService.hasPermission(PortalUserPermission.EditInterviewer);        
    }

    ngOnInit(): void {
        this.getInterviewerDetails();        
    }

    ngOnDestroy(): void {
        if (this.changeSubscription) this.changeSubscription.unsubscribe();
    }

    getInterviewerDetails(): void {
        this.isLoading = true;
        this.interviewerService
            .getInterviewerDetails(this.interviewerId)
            .subscribe({
                next: (result: AgencyInterviewerDetailsResponse) => {
                    if (result?.details?.isOnlineInterviewer === false) {
                        this.isOnlineInterviewer = false;
                    }

                    this.setupForm();
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            })
    }

    private setupForm() {
        this.form = this.userForm.createInterviewerProfileForm(this.isReadonly, this.isOnlineInterviewer);
        this.changeSubscription = this.form.change$
            .subscribe((chg: MFValueChange) => {
                this.changesMade = true;
            });

        if (this.interviewer) {
            this.form.setValue('personalNotes', this.interviewer!.notes);
            this.form.setValue('interviewerBio', this.interviewer!.interviewerBio);
            this.form.setValue('messageTemplate', this.interviewer!.messageTemplate);
            this.changesMade = false;
        }
    }

    saveChanges(): void {
        if (this.form) {
            this.isProcessing = true;
            const notes = this.form.getValue('personalNotes');
            const bio = this.form.getValue('interviewerBio');
            const msgTemplate = this.form.getValue('messageTemplate');

            console.log(msgTemplate);

            this.interviewerService
                .updateBioAndNotes(this.interviewerId, notes, bio, msgTemplate)
                .subscribe({
                    next: (data: boolean) => {
                        this.interviewerService.notifyInterviewerDetailChanged(new InterviewerDetailChanged(InterviewerChangedItem.Profile, this.interviewerId));

                        this.isProcessing = false;
                        this.changesMade = false;
                        this.showAlert(`Details Updated`, `Details have been updated successfully.`, undefined, true);
                    },
                    error: (error: any) => {
                        this.isProcessing = false;
                        if (error instanceof HttpErrorResponse) {
                            // We've already shown the error
                            if (error.status === 401) return;
                        }
                        console.error(error);
                        this.showAlert(`Details`, `There was an error updating the database:`, error.message);
                    }
                });
        }
    }


    showAlert(title: string, explain: string, error: string | undefined = undefined, closeParentAfter = false) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = explain;
        modalRef.componentInstance.cssClass = error === undefined ? "info" : "danger";
        if (error)
            modalRef.componentInstance.error = error;

        modalRef.result.then(result => {
            if (result) {
                if (closeParentAfter) {
                    this.closeModal();
                }
            }
        }).catch((err: any) => { });
    }

    onFormEvent(event: MetaFormUserEvent): void {
        switch (event.type) {
            case UserEventType.FormInitialised:
                break;
            case UserEventType.FormInvalid:
                this.isValid = false;
                break;
            case UserEventType.FormValid:
                this.isValid = true;
                break;
            case UserEventType.NavigationButtonClickedBack:
                break;
            case UserEventType.NavigationButtonClickedForward:
                break;
            case UserEventType.FormSubmit:
                // this.passwordData = {
                //     newPassword: this.form.getValue('newPassword'),
                //     passwordConfirmation: this.form.getValue('passwordConfirmation')
                // };
                // this.doResetPassword();
                break;
        }
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    changeSubscription?: Subscription;
    isReadonly = false;   

    userId = -1;
    isLoading = true;

    isValid = false;
    isProcessing = false;
    changesMade = false;

    emailAddress?: string;
    phone?: string;

    public form!: MetaForm;
}