import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';

import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';

import { Observable, Subject, catchError } from 'rxjs';

import { AuthenticationService } from '../auth/authentication.service';


@Injectable({
    providedIn: 'root'
})

@Injectable()
export class MapService extends BaseService {

    public constructor(
        private http: HttpClient
    ) {
        super();
    }
    
    public getPlace(placeId: string): Observable<MapPlace> {
        const s = new Subject<MapPlace>();

        // console.log(`Looking for ${placeId}`);
        let headers = new HttpHeaders()
            .set('X-Goog-Api-Key', environment.googleMaps.apiKey)
            .set('X-Goog-FieldMask', 'formattedAddress,displayName')
            .set('Content-Type', 'application/json');
        // console.log(`Headers: ${JSON.stringify(headers)}`);
        this.http.get(`https://places.googleapis.com/v1/places/${placeId}`, {'headers': headers})
            .subscribe({
                next: (r: any) => {
                    // console.log(`Got place: ${JSON.stringify(r, null, 2)}`);
                    const value = { name: r.displayName.text, address: r.formattedAddress };
                    // console.log(`Got place: ${JSON.stringify(value, null, 2)}`);
                    s.next(value);
                },
                error: (error: any) => {
                    // console.error(`EEK:`, error);
                    s.error(error);
                }
            });
        
        return s;
    }
}

export interface MapPlace {
    name: string;
    address: string;
}