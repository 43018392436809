<div class="Map">
    <div class="form-group" *ngIf="showSearchTextbox">
        <input type="text" class="my-search-box" placeholder="Search Location"
            type="text" #search />
        <button class="btn-location" (click)="getCurrentLocation()">
            <i class="fas fa-lg fa-crosshairs" aria-hidden="true"></i>
        </button>
    </div>
    <div class="mapcontainer">
        <google-map height="300px" width="100%" class="google-map" #map="googleMap" [options]="mapOptions" [center]="mapCenter" [zoom]="zoomLevel" (mapClick)="mapClicked($event)">
            <map-marker #marker="mapMarker" *ngFor="let markerPosition of markerPositions; let i = index;"
                [position]="markerPosition" [options]="markerOptions" (mapClick)="openInfoWindow(marker, i)">
                <map-info-window [innerHtml]="infoText"></map-info-window>
            </map-marker>
            <map-marker *ngIf="locationPosition" [position]="locationPosition" [options]="locationMarkerOptions"></map-marker>
        </google-map>
    </div>
</div>