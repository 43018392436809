
<div class="interviewer-page">
    <app-interviewer-details-sidebar [interviewer]="interviewer"></app-interviewer-details-sidebar>

    <div class="interviewer-content">
        <div class="float-container">
            <div class="interviewer-details" *ngIf="form">
                <lib-metaform-display [form]="form" [showButtons]="false" (formEvent)="onFormEvent($event)"></lib-metaform-display>
                <div class="button-bar">
                    <button type="button" class="btn btn-success" [disabled]="!(isValid && changesMade) || isProcessing" (click)="saveChanges()">Save<img *ngIf="isProcessing" src="img/white-loader.gif"></button>
                </div>
            </div>

            <div *ngIf="!isOnlineInterviewer" class="interviewer-details-location"> 
                           
                <div class="button-bar">
                    <button type="button" class="btn btn-success" (click)="addLocation()">Add location</button>
                </div>
                <div *ngIf="locations">
                    <app-interview-loc-list [locations]="locations" [interviewerId]="interviewerId" (locationSelected)="onLocationSelected($event)"></app-interview-loc-list>                
                </div>
            </div>
        </div>
    </div>
</div>
