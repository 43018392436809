import { Component, OnInit } from '@angular/core';
import { InterviewerService, InterviewSlot, InterviewSlotResponse, UpdatedSlot } from './interviewer.service';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Day, SelectedTimeSlot, TimeSlot } from '@aifs-shared/calendar/day';
import { CalendarItem } from '@aifs-shared/calendar/calendar-item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { AlertModal, ConfirmationModal } from '@aifs-shared/modals';
import { Title } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { CanDeactivateComponent } from '@aifs-shared/auth/guard.fn';

@Component({
    selector: 'app-general-availability',
    templateUrl: './interviewer-general-availability.component.html',
    styleUrls: ['./style/interviewer-general-availability.component.scss']
})
export class InterviewerGeneralAvailabilityComponent implements OnInit, CanDeactivateComponent {

    constructor(
        private title: Title,
        private modalService: NgbModal,
        private interviewer: InterviewerService,
        private userService: PortalUserService) {
    }

    ngOnInit(): void {
        this.title.setTitle('Camp America Management Portal');

        const iv = this.userService.getInterviewerId();
        this.interviewer
            .getInterviewSlotsForInterviewer(iv)
            .subscribe({
                next: (result: InterviewSlotResponse) => {
                    // console.log(`Got: ${JSON.stringify(result, null, 2)}`);
                    const slots = result.slots;
                    if (result.exclusionFrom) {
                        this.exclusionDate = DateTime.fromISO(result.exclusionFrom);
                        // console.log(`Got exclusion date: ${this.exclusionDate}`);
                    }

                    if (slots.length == 0) {
                        console.log(`Didn't get any slots`)
                    } else {
                        this.generateTimeSlotsAndDays(slots);
                        this.interviewerTimezone = result.slots[0].tzTimezoneCode;
                    }
                    this.isLoading = false;
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            });
    } 

    canDeactivate(): Observable<boolean> | boolean {
        const s = new Subject<boolean>();
        if (this.changesMade) {
            const modalRef = this.modalService.open(ConfirmationModal);
            modalRef.componentInstance.title = 'Discard Changes';
            modalRef.componentInstance.body = `Are you sure you want to discard your changes?`;
            modalRef.result.then((result: boolean ) => {
                s.next(result);
                s.complete();
            }).catch((err: any) => { });
            return s.asObservable();
        } else {
            return true;
        }
    }

    timeSlotSelected(selection: SelectedTimeSlot) {
        this.selectedDay = selection.day;
        this.selectedTimeSlot = selection.timeSlot;
        const interviewerId = this.userService.getInterviewerId();
        if (this.selectedTimeSlot) {
            // console.log(`Changes were made: `);
            const slot = this.selectedTimeSlot;
            this.updateSlot(slot.id, slot.isAvailable);
        } else {
            const day = this.selectedDay;
            if (day) {
                day.slots.forEach(sl => {
                    this.updateSlot(sl.id, sl.isAvailable);
                });
            }
        }
    }

    userSelectedDate(selection: CalendarItem) {
        //console.log(`date selected: ${selection.date}`);
    }

    changeExclusion(date: DateTime | undefined): void {
        //console.log(`Exlusion date was changed: ${date}`)
        this.exclusionDate = date;

        if (date == undefined && this.changedExclusionDate) {
            //console.log(`clearing date, changes already made`);
            this.changesMade = false;
            this.changedExclusionDate = false;
        } else {
            //console.log(`setting date`);

            this.changesMade = true;
            this.changedExclusionDate = true;
            if (date == undefined) {
                // Only save if the changed date was saved
                this.saveChanges();
            }
        }
    }

    changeTimezone(timezone: string | undefined): void {
        if (timezone) {
            this.interviewerTimezone = timezone;
            this.changesMade = true;
            this.changedInterviewerTimezone = true;
            
            const date = new Date();
            const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
            const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));

            // Get the time difference in milliseconds and convert to minutes minus full hours
            const diff = utcDate.getTime() - tzDate.getTime();
            const offsetInMinutes = Math.abs(diff / (1000 * 60) % 60);
            const formattedMinutes = offsetInMinutes.toString().padStart(2, '0');

            this.days.forEach(d => 
                d.slots.forEach(s => {
                        const [shours, sminutes] = s.startTime.split(':');
                        s.startTime = `${shours}:${formattedMinutes}`;
                        const [ehours, eminutes] = s.startTime.split(':');
                        s.endTime = `${ehours}:${formattedMinutes}`;
                        s.name = s.startTime;
                    }
                )
            )
        }
    }

    saveChanges() {
        this.isProcessing = true;
        const interviewerId = this.userService.getInterviewerId();

        this.saveChangedSlots(interviewerId);
    }

    private saveChangedSlots(interviewerId: number): void {
        let exclusionDateFrom = this.exclusionDate ? this.exclusionDate!.toISODate() : '';
        let interviewerTimezone = this.interviewerTimezone;
        if (this.changedExclusionDate && this.exclusionDate) {
            exclusionDateFrom = this.exclusionDate ? `${this.exclusionDate!.toISODate()}` : '';
        }
        this.interviewer
            .updateInterviewSlotAvailability(interviewerId, this.changedSlots, interviewerTimezone!, exclusionDateFrom!)
            .subscribe({
                next: (result: InterviewSlot) => {
                    // Not sure what we want to do with this...
                    this.changesMade = false;
                    this.changedSlots = [];
                    this.isProcessing = false;
                    this.changedExclusionDate = false;
                    this.showModal("General Availability", "Your changes have been saved.");
                },
                error: (error) => {
                    console.error(error);
                    this.isProcessing = false;
                }
            });
    }

    private updateSlot(slotId: number, isAvailable: boolean) {
        // console.log(`Updating ${slotId}. Slots in list: ${this.changedSlots.length}`);

        const slot = this.readSlots.find(sl => sl.availabilitySlotId == slotId);
        if (!slot) {
            console.error(`Missing slot: ${slotId}`);
        } else {
            const alreadyUpdated = this.changedSlots.find(cs => cs.availabilitySlotId == slotId);
            if (!alreadyUpdated) {
                // console.log(`New slot`);
                const ts = new UpdatedSlot(slotId, isAvailable);
                this.changedSlots.push(ts);
            } else {
                // console.log(`Updating existing: ${JSON.stringify(alreadyUpdated)}`);
                alreadyUpdated.isAvailable = isAvailable;
            }
        }

        // console.log(`Slots: ${JSON.stringify(this.changedSlots, null, 2)}`);
        this.changesMade = true;
        // console.log(`Slots in list: ${this.changedSlots.length}`);

    }

    private generateTimeSlotsAndDays(slots: InterviewSlot[]) {
        this.readSlots = slots;
        var day = 0;
        while (day < 8) {
            day++;
            const daySlots = slots.filter(s => s.dayOfWeek === day);
            if (daySlots && daySlots.length > 0) {
                const d = new Day(daySlots[0].day.substring(0,3), true, day);
                daySlots.forEach(ds => {
                    d.slots.push(new TimeSlot(ds.availabilitySlotId, ds.startTime, ds.endTime, ds.isAvailable));
                });
                this.days.push(d);
            }
        }
    }

    private showModal(title: string, message: string ) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = message;

        modalRef.result.then(
            result => {
                if (result) {

                }
            }).catch((err: any) => { });
    }

    changedSlots: UpdatedSlot[] = [];
    readSlots: InterviewSlot[] = [];

    isLoading = true;
    changesMade = false;
    isProcessing = false;

    days: Day[] = [];
    selectedDay?: Day;
    selectedTimeSlot?: TimeSlot;

    exclusionDate?: DateTime;
    changedExclusionDate = false;

    interviewerTimezone: string = '';
    changedInterviewerTimezone = false;
}

