<div class="loc">
    <div class="loc-summary" (click)="onClick()">
        <div class="details">
            <label>Location name:</label>
            <span>{{location.name}}</span>
            <label>Availability:</label>
            <span>{{location.availability}}</span>
        </div>
        <div class="actions">
            <ng-container *ngIf="canEditActiveStatus">
            <lib-metaform-display [form]="form" [showButtons]="false" (formEvent)="onFormEvent($event)" (click)="$event.stopPropagation()"></lib-metaform-display>
            </ng-container>
        </div>
    </div>
    <span *ngIf="onlyOne" class="annotation error">At least one location must be active!</span>
    <span *ngIf="atLimit" class="annotation error">You may only have eight locations active at a time.</span>
</div>