<div class="calendar-container" *ngIf="!inError">
    <div class="calendar-days">
        <div class="nav"><i class="fas fa-chevron-left" [class.disabled]="!canViewPrevious"
                (click)="previousDates()"></i></div>
        <div class="day" *ngFor="let day of days">
            <div class="heading"><span>{{day.dayOfWeek}}</span><span>{{day.date}}</span></div>
            <div class="slots" *ngFor="let s of day.slots" (click)="selectSlot(s)">
                <div class="slot" [ngClass]="slotStyle(s)" title="{{slotTitle(s)}}"><span class="text">{{s.startTime}}</span></div>
            </div>
        </div>
        <div class="nav"><i class="fas fa-chevron-right" [class.disabled]="!canViewNext" (click)="nextDates()"></i>
        </div>
    </div>
</div>