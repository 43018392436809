import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { ApplicantDetailChanged, ChangedItem } from '../applicant/applicant-detail-changed';
import { ApplicantService } from '../applicant/applicant.service';
import { ApplicantQueueItem, InterviewReportSubmitTimeAverage, InterviewerQueueResponse, InterviewerQueueType, InterviewerService } from './interviewer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IncompleteInterviewReportsModalComponent } from './incomplete-interview-reports-modal.component';
import { IncompleteInterviewReportSortEvent, NgbdIncompleteInterviewReportSortableHeader, compare, compareBool, compareNumber } from './NgbdSortableHeader';

@Component({
    selector: 'app-interview-report-incomplete',
    templateUrl: './incomplete-interview-reports.component.html',
    styleUrls: ['./style/applicant-view-styles.scss']
})
export class IncompleteInterviewReportsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() interviewerId: number = 0;
    @Input() showTable = true;
    @Input() filterCountry = '';

    // @Output() itemSelected = new EventEmitter<SelectedTimeSlot>();
    // @Output() changesMade = new EventEmitter<Day>();

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private appService: ApplicantService,
        private userService: PortalUserService,
        private interviewer: InterviewerService) {
    }

    ngOnInit(): void {
        this.getIncompleteInterviewReports();
        this.changeSubscription = this.appService.applicantDetailsChanged$.subscribe({
            next: (changeItem: ApplicantDetailChanged) => {
                if (changeItem.changedItem === ChangedItem.Interview || changeItem.changedItem === ChangedItem.ApplicationSubmittedToOffice) {
                    const inList = this.applicants.findIndex(a => a.applicationId === changeItem.applicationId);
                    if (inList > -1) this.getIncompleteInterviewReports();
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filterCountry']) {
            this.isLoading = true;
            this.filterByCountry();
            this.isLoading = false;
        } 
    }

    ngOnDestroy(): void {
        this.changeSubscription.unsubscribe();
    }

    private filterByCountry() {
        if (this.filterCountry != ''){
            this.softedFilteredList = this.sortedList.filter(a => a.countryCode === this.filterCountry);
        }
        else {
            this.softedFilteredList = this.sortedList;
        }
        this.count = this.softedFilteredList.length;
    }

    private getIncompleteInterviewReports(): void {
        this.noData = true;

        this.isLoading = true;

        // Are we reading for a specific interviewer (e.g. from an interviewer/{id} link)?
        if (this.interviewerId > 0) {
            this.loadDetailsForInterviewer();
        } else {
            // Is this for a specific role?
            if (this.userService.hasStaffRole()) {
                this.loadDetailsForStaff();
            }
            else if (this.userService.hasAgentRole()) {
                this.loadDetailsForAgent();
            }
            if (this.userService.hasInterviewerRole()) {
                this.loadDetailsForInterviewer();
            }
        }
    }

    loadDetailsForInterviewer() {
        const iv = this.interviewerId === 0 ? this.userService.getInterviewerId() : this.interviewerId;
        this.interviewer
            .getApplicantByQueueForInterviewer(iv, InterviewerQueueType.IncompleteInterviewReports)
            .subscribe({
                next: (result: InterviewerQueueResponse) => {
                    this.populateData(result);
                },
                error: (error: any) => { console.error(error); this.isLoading = false; }
            });
        
        this.interviewer
            .getInterviewReportSubmitTime(iv)
            .subscribe({
                next: (result: InterviewReportSubmitTimeAverage) => {
                    if (result) {
                        const avg = result.submitDayAverage;
                        if (avg && avg >= 0) {
                            const pl = avg != 1 ? 's' : '';
                            this.submitTimeDays = `${avg} day${pl}`;
                        }
                    }
                },
                error: (error: any) => { console.error(error); this.isLoading = false; }
            });
    }

    loadDetailsForAgent() {
        const agentId = this.userService.getAgentId();
        this.showInterviewer = true;
        this.interviewer.getApplicantByQueueForAgent(agentId, InterviewerQueueType.IncompleteInterviewReports)
            .subscribe(
                {
                    next: (result: InterviewerQueueResponse) => {
                        this.populateData(result);
                    },
                    error: (error: any) => { console.error(error); this.isLoading = false; }
                });
    }

    loadDetailsForStaff() {
        const staffId = this.userService.getStaffId();
        this.showInterviewer = true;
        this.interviewer.getApplicantByQueueForStaff(staffId, InterviewerQueueType.IncompleteInterviewReports)
            .subscribe(
                {
                    next: (result: InterviewerQueueResponse) => {
                        this.populateData(result);
                    },
                    error: (error: any) => { console.error(error); this.isLoading = false; }
                });
    }
    
    private populateData(result: InterviewerQueueResponse) {
        this.sortedList = [];
        this.sortColumn = '';
        this.sortDirection = '';
        if (result.details) {
            this.sortedList = [];
            this.sortColumn = '';
            this.sortDirection = '';
            this.applicants = result.details.items;
            this.sortedList = this.applicants;
            this.isLoading = false;
            this.filterByCountry();
            this.noData = (this.count === 0);
        }
    }

    @ViewChildren(NgbdIncompleteInterviewReportSortableHeader) headers: QueryList<NgbdIncompleteInterviewReportSortableHeader> | undefined;
    onSort({ column, direction }: IncompleteInterviewReportSortEvent): void {

        if (!this.applicants) {
            console.log(`No data?`);
            return;
        }

        let list: ApplicantQueueItem[] = [];
        this.sortColumn = column;
        this.sortDirection = direction;

        // sorting countries
        if (direction === '' || column === '') {
            list = this.applicants;
        } else {
            list = [...this.applicants].sort((a, b) => {
                var res: number = 0;
                if (typeof a[column] === "string") {
                    res = compare(a[column] as string | undefined, b[column] as string | undefined);
                } else if (typeof a[column] === "number") {
                    res = compareNumber(a[column] as number | undefined, b[column] as number | undefined);
                }
                return direction === 'asc' ? res : -res;
            });
        }
        this.sortedList = list;
        this.filterByCountry();
    }

    sortType(column: string): string {
        if (column === this.sortColumn) {
            // console.log(`sorted! ${column}, ${this.sortDirection}`);
            return `${this.sortDirection}`;
        }
        return `unsorted`;
    }

    openApplicant(a: ApplicantQueueItem): void {
        const link = `/applicant/${a.applicantId}/${a.applicationId}`;
        // console.log(`Showing applicant ${applicantId}`);
        this.router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    showTableDialog(): void {
        if (this.showTable) return;

        const modalRef = this.modalService.open(IncompleteInterviewReportsModalComponent, { size: 'lg', windowClass: 'modal', scrollable: true });
        modalRef.componentInstance.interviewerId = this.interviewerId;
        modalRef.componentInstance.applicants = this.applicants;
        modalRef.componentInstance.showInterviewer = this.showInterviewer;
        modalRef.componentInstance.title = 'Incompleted reports';
        modalRef.result.then().catch((err: any) => { });
    }

    useInterviewerId: number = 0;
    noData = false;

    applicants: ApplicantQueueItem[] = [];
    count = 0;

    isLoading = true;
    showInterviewer = false;
    changeSubscription!: Subscription;
    sortedList: ApplicantQueueItem[] = [];
    softedFilteredList: ApplicantQueueItem[] = [];
    sortColumn = 'interviewDateTime';
    sortDirection = 'asc';

    submitTimeDays = '';
}