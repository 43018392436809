<div [class.clickable]="!showTable && count > 0" (click)="showTableDialog()">
    <h3>Completed Interviews <span *ngIf="count > 0">{{count}}</span></h3>
    <div class="complete" *ngIf="!isLoading; else still_loading">
        <table *ngIf="!noData && showTable" class="table table-striped table-fixed">
            <tbody>
                <tr *ngFor="let a of applicants" class="applicant" (click)="openApplicant(a)">
                    <td>
                        <app-round-image class="profile-image" [url]="a.profile" [useDefaultMissingImage]="true" [width]="30" [height]="30"></app-round-image> {{a.name}}
                        <span *ngIf="a.status > 0" container="body" ngbPopover="{{a.statusInfo}}" popoverClass="slot-popover"
                            triggers="mouseenter:mouseleave" popoverTitle="Note"><i class="warning fa-solid fa-circle-exclamation"></i></span>
                    </td>
                    <td class="state">{{a.userState}}</td>
                </tr> 
            </tbody>
        </table>
        <div *ngIf="noData"><p>No completed interview reports.</p></div>
    </div>
    <ng-template #still_loading>
        <div class="loading"><div class="load_container">
            <img src="img/stopwatch.svg" class="bl">
        </div></div>
    </ng-template>
</div>