import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { GoogleMapsModule } from '@angular/google-maps';
import { environment } from '@environments/environment';
import { MapComponent } from './map.component';
import { MapInfoWindowComponent } from './map-info-window.component';

export const MAP_LOADED = new InjectionToken<Observable<boolean>>('Google Maps API Loaded');

function loadMapApi(httpClient: HttpClient) {
    console.log(`Loading Google Maps API`);
    return httpClient
        .jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleMaps.apiKey}&libraries=places,marker`, 'callback')
        .pipe(
            shareReplay({ bufferSize: 1, refCount: true }),
            map(() => true),
            catchError(e => {
                console.log(e);
                return of(false);
            })
        );
}
@NgModule({
    imports: [
        CommonModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
    ],
    declarations: [
        MapComponent,
        MapInfoWindowComponent
    ],
    providers: [
        { provide: MAP_LOADED, useFactory: loadMapApi, deps: [HttpClient] },
    ],
    exports: [
        MapComponent
    ]
})
export class MapModule { }
