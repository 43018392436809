import { CAMapMarker } from "@aifs-shared/map/map-marker";

export interface LocationDetails {
    id: number;
    name: string;
    location: CAMapMarker;
    availability: string;
    isActive: boolean;
}

export class LocationDetailsImpl implements LocationDetails {
    id: number;
    name: string;
    location: CAMapMarker;
    availability: string;
    isActive: boolean;

    constructor(id: number, name: string, location: CAMapMarker, availability: string, isActive: boolean) {
        this.id = id;
        this.name = name;
        this.location = location;
        this.availability = availability;
        this.isActive = isActive;
    }
}