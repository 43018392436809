import { RoleName } from '@aifs-shared/user/user';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InterviewerCalendarComponent } from './interviewer-calendar.component';
import { InterviewerDetailsComponent } from './interviewer-details.component';
import { InterviewerGeneralAvailabilityComponent } from './interviewer-general-availability.component';
import { InterviewerProfileComponent } from './interviewer-profile.component';
import { InterviewerSearchComponent } from './interviewer-search.component';
import { InterviewerSummaryComponent } from './interviewer-summary.component';
import { authGuard, canDeactivateGuard } from '@aifs-shared/auth/guard.fn';

const routes: Routes = [
    {
        path: 'profile', component: InterviewerProfileComponent,
        canActivate: [authGuard],
        data: {
            minimumRole: RoleName.Interviewer
        }
    },
    {
        path: 'details', component: InterviewerDetailsComponent,
        canActivate: [authGuard],
        data: {
            minimumRole: RoleName.Interviewer
        }
    },
    {
        path: 'calendar', component: InterviewerCalendarComponent, canActivate: [authGuard],
        canDeactivate: [canDeactivateGuard],
        data: {
            minimumRole: RoleName.Interviewer
        }
    },
    {
        path: 'summary', component: InterviewerSummaryComponent, canActivate: [authGuard],
        canDeactivate: [canDeactivateGuard],
        data: {
            minimumRole: RoleName.Interviewer
        }
    },
    {
        path: 'general-availability', component: InterviewerGeneralAvailabilityComponent, canActivate: [authGuard],
        canDeactivate: [canDeactivateGuard],
        data: {
            minimumRole: RoleName.Interviewer
        }
    },
    {
        path: ':id', component: InterviewerProfileComponent,
        canActivate: [authGuard],
        data: {
            minimumRole: RoleName.Agent
        }
    },
    {
        path: '', component: InterviewerSearchComponent,
        canActivate: [authGuard],
        data: {
            minimumRole: RoleName.Agent
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class InterviewerRoutingModule { }
