import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { InterviewCalendarItem, InterviewerService, InterviewSlot, InterviewSlotResponse } from './interviewer.service';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Change, Day, SelectedTimeSlot, TimeSlot } from '@aifs-shared/calendar/day';
import { CalendarItem } from '@aifs-shared/calendar/calendar-item';
import { MonthChangeEvent, MonthComponent, WeekSelectedEvent } from '@aifs-shared/calendar/month.component';
import { Month } from '@aifs-shared/calendar/month';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal, ConfirmationModal } from '@aifs-shared/modals';
import { Observable, Subject } from 'rxjs';
import { CanDeactivateComponent } from '@aifs-shared/auth/guard.fn';

@Component({
    selector: 'app-interviewer-calendar',
    templateUrl: './interviewer-calendar.component.html',
    styleUrls: ['./style/interviewer-calendar.component.scss']
})
export class InterviewerCalendarComponent implements OnInit, AfterViewInit, CanDeactivateComponent {
    @ViewChild(MonthComponent) calendar!: MonthComponent;
    monthCalculator: Month = new Month();

    constructor(
        private router: Router,
        private title: Title,
        private modalService: NgbModal,
        private interviewer: InterviewerService,
        private userService: PortalUserService) {
        this.calendarDate = new Date(Date.now());
        this.monthCalculator = Month.calculate(this.calendarDate.getMonth(), this.calendarDate.getFullYear());
    }

    ngOnInit(): void {
        this.title.setTitle('Camp America Management Portal');

        // INT-39
        const tooLateDay = DateTime.now().plus({ days: 15 });
        // CA-2502
        const tlv = `${tooLateDay.year}-${tooLateDay.month.toString().padStart(2, '0')}-${tooLateDay.day.toString().padStart(2, '0')}T22:00:00`;
        //console.log(`TooLate: ${tlv}`);
        this.tooEarly = DateTime.now().plus({ hours: 48 });
        this.tooLate = DateTime.fromISO(tlv); // DateTime.now().plus({ days: 15 }); // Matches usp_sel_interview_availability_for_application

    }

    ngAfterViewInit(): void {
        this.isLoading = true;
        this.loadInterviewerAvailability()
            .subscribe({
                next: (finished: boolean) => {
                    this.isLoading = false;
                }, 
                error: (error) => {
                    this.isLoading = false;
                }
            });
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        const s = new Subject<boolean>();
        if (this.changesMade) {
            const modalRef = this.modalService.open(ConfirmationModal);
            modalRef.componentInstance.title = 'Discard Changes';
            modalRef.componentInstance.body = `Are you sure you want to discard your changes?`;
            modalRef.result.then((result: boolean) => {
                s.next(result);
                s.complete();
            }).catch((err: any) => { });
            return s.asObservable();
        } else {
            return true;
        }
    }

    changeWeek(direction: number): void {
        const newWeek = this.activeWeek + direction;
        // console.log(`Want to update week from ${this.activeWeek} to ${newWeek}`);
        
        if (newWeek <= this.monthCalculator.weeks.length - 1 && newWeek > -1) {
            this.calendar.selectWeek(this.monthCalculator.weeks[newWeek]);
        } else {
            var week = 0;
            const firstDayShown = this.monthCalculator.weeks[this.activeWeek].days[0].day;
            // console.log(`First day currently showing is ${firstDayShown}`);
            if (direction < 0) {
                this.pauseUpdate = true;
                this.calendar.previousMonth();
                var week = this.monthCalculator.weeks.length - 1; 
            }
            if (direction > 0) {
                this.pauseUpdate = true;
                this.calendar.nextMonth();
                var week = 0; 
            }

            if (this.monthCalculator.weeks[week].days[0].day === firstDayShown) {
                // console.log(`Have already seen this week, skip it`);
                week += direction;
            }

            this.pauseUpdate = false;
            this.calendar.selectWeek(this.monthCalculator.weeks[week]);            
        }
    }

    pauseUpdate = false;

    newWeekSelected(event: WeekSelectedEvent) {
        // console.log(`New date selected: ${event.week.getTitle()}`);
        this.activeWeek = event.week.id;
        this.monthCalculator.activeWeek = this.activeWeek;
        this.generateTimeSlotsAndDays();
    }

    newMonthSelected(event: MonthChangeEvent) {
        const month = Month.calculate(event.newMonth, event.newYear);
        if (event.useLastWeek) {
            // console.log(`new month desired! ${event.newMonth}, ${event.newYear}`);
            // console.log(`use last week ${month.weeks.length - 1}`);
            this.activeWeek = month.weeks.length - 1;
            // console.log(`active week is: ${month.weeks[this.activeWeek].getTitle()}`);
        } else {
            // console.log(`use first week ${0}`);
            this.activeWeek = 0;
        }
        month.activeWeek = this.activeWeek;
        // Set this last as it triggers an OnChanges event in the calendar
        this.monthCalculator = month;
        
        if (this.pauseUpdate) return;
        this.generateTimeSlotsAndDays();
    }

    calendarItemSelected(ts: SelectedTimeSlot) {
        // console.log(`Selected: ${ts.day.name} ${JSON.stringify(ts.timeSlot)}`);
        if (!ts.timeSlot!.isAvailable) {
            // console.warn(`Slot is not available`);
            return;
        }

        const exclusionDate = this.exclusionDateFrom;
        if (!ts.timeSlot!.isBooked) {
            let isLocked = !ts.timeSlot!.isLocked;
            const week = this.monthCalculator.weeks[this.activeWeek];
            const date = week.days[0].date.plus({ days: ts.day.dayOfWeek - 1 });

            // Prevent selections past the exclusion date
            const past = exclusionDate && date >= exclusionDate;
            if (past) {
                // console.warn(`past the exclusionDate`);
                return;
            }

            // console.log(`Before`, this.changedSlots);
            const exists = this.changedSlots.findIndex(cs => cs.timeSlot === ts.timeSlot!.name && cs.date.toISODate() === date.toISODate());
            if (exists >= 0) {
                const slot = this.changedSlots[exists];
                if (slot) {
                    // if (slot.originalValue != isLocked) {
                    //     // The original value has changed
                    //     // console.log(`Original value changed from ${slot.originalValue} to ${isLocked}`);
                    // }
                    // console.log(`Change to existing : ${JSON.stringify(slot)}`);
                    slot.newValue = isLocked;
                }
            } else {
                this.changedSlots.push(new Change(date, ts.timeSlot!.name, isLocked, ts.timeSlot!.isLocked));
            }

            ts.timeSlot!.isLocked = isLocked;

            //const updated = this.changedSlots.filter(cs => cs.originalValue != cs.newValue);
            //this.changedSlots = updated.slice();
            // console.log(`Changed`, this.changedSlots);

            // Check for duplications
            this.changesMade = this.changedSlots.length > 0;
        }
    }

    loadInterviewerAvailability(): Observable<boolean> {
        const s = new Subject<boolean>();

        const iv = this.userService.getInterviewerId();
        this.interviewer
            .getInterviewSlotsForInterviewer(iv)
            .subscribe({
                next: (result: InterviewSlotResponse) => {
                    const slots = result.slots;
                    if (slots.length != 0) {
                        this.readSlots = slots;
                    }

                    if (result.exclusionFrom) {
                        this.exclusionDateFrom = DateTime.fromISO(result.exclusionFrom);
                        // console.log(`Got exclusion date: ${this.exclusionDateFrom}`);
                    }

                    // var exclusions = slots.filter(s => s.isAvailable == false);

                    // if (exclusions) {
                    //     //console.log(`Got exclusion slots: ${JSON.stringify(exclusions)}`);
                    //     // exclusions.forEach(s => {
                    //     //     this.changedSlots.push(new Change(DateTime.fromISO(s.day), s.exclusionTime, s.isLocked, s.isLocked));
                    //     // })
                    // }

                    s.next(true);
                },
                error: (error) => {
                    console.error(error);
                    s.error(error);
                }
            });   
        
        return s;
    }

    changeAvailability() {
        this.router.navigateByUrl("/interviewer/general-availability");
    }

    /**
     * @description Using the general availability slots, build the week calendar,
     * starting with the current week
     * @param generalAvailabilitySlots - InterviewSlot array from server
     */
    private generateTimeSlotsAndDays(actualSlots?: CalendarItem[]) {
        this.days = [];
        var excl: calendar_item[] = [];

        const week = this.monthCalculator.weeks[this.activeWeek];
        //console.log(`Got active week: ${week.getTitle()}, firstDate: ${week.getFirstDate()}`);

        this.isRefreshing = true;
        const iv = this.userService.getInterviewerId();
        this.interviewer
            .getInterviewsForInterviewer(iv, week.getFirstDate(), week.getLastDateForRange())
            .subscribe({
                next: (res: InterviewCalendarItem[]) => {
                    // console.log(`Got: ${JSON.stringify(res)}`);

                    res.forEach(ic => {
                        excl.push(new calendar_item(DateTime.fromISO(ic.startDate), DateTime.fromISO(ic.endDate), ic.entryType, ic.name));
                    });

                    if (this.readSlots && this.readSlots.length > 0) {
                        for (let day of week.days) {
                            const daySlots = this.readSlots.filter(s => s.dayOfWeek === day.dayOfWeek);
                            if (daySlots) {
                                const d = new Day(day.date.weekdayShort!, true, day.dayOfWeek);
                                daySlots.forEach(ds => {
                                    var ts = new TimeSlot(ds.availabilitySlotId, ds.startTime, ds.endTime, ds.isAvailable);
                                    
                                    const slotDate1 = DateTime.fromISO(`${day.date.toISODate()}T${ds.startTime}`);
                                    const slotDate2 = DateTime.fromISO(`${day.date.toISODate()}T${ds.endTime}`).minus(60000);

                                    const ex: calendar_item[] = excl.filter(e => e.contains(slotDate1, slotDate2));
                                    if (ex.length > 0) {
                                        ex.forEach(e => {
                                            if (e.type === 1) {
                                                ts.isBooked = true;
                                                ts.interviewName = e.name;
                                            }
                                            if (e.type === 2) ts.isLocked = true;
                                        });
                                    }

                                    if (this.exclusionDateFrom && slotDate1 > this.exclusionDateFrom) {
                                        ts.isLocked = true;
                                        ts.isApplicantUnselectable = true;
                                    }

                                    if (slotDate1 < this.tooEarly || slotDate2 > this.tooLate) {
                                        ts.isApplicantUnselectable = true;
                                    }

                                    const exists = this.changedSlots.findIndex(cs => cs.timeSlot === ts.name && cs.date.toISODate() === day.date.toISODate());
                                    if (exists > -1) {
                                        //console.log(`There's a change here ${day.date.toISODate()} ${ts.name}`);
                                        ts.isLocked = this.changedSlots[exists].newValue;
                                    }


                                    d.slots.push(ts);
                                });
                                this.days.push(d);
                            }
                            else {
                                console.warn(`No daySlot found for ${day.dayOfWeek}`);
                            }
                        }
                    } else {
                        console.warn(`No readSlots?`, this.readSlots);
                    }

                    this.weekName = this.monthCalculator.weeks[this.activeWeek].getTitle();

                    this.isRefreshing = false;
                },
                error: (error) => {
                    console.error(error);

                    this.isRefreshing = false;
                }
            });
        
    }
        
    saveChanges(): void {
        const iv = this.userService.getInterviewerId();
        this.isProcessing = true;

        this.interviewer
            .updateInterviewSlotExclusions(iv, this.changedSlots)
            .subscribe({
                next: (r) => {
                    this.changedSlots = [];
                    this.changesMade = false;

                    this.showModal("General Availability", "Your changes have been saved.");

                    this.loadInterviewerAvailability()
                        .subscribe({
                        next: (finished: boolean) => {
                            this.generateTimeSlotsAndDays();
                            this.isProcessing = false;
                        }, 
                        error: (error) => {
                            this.isProcessing = false;
                        }
                    });                
                },
                error: (error) => {
                    this.isProcessing = false;
                    console.error(error);
                }
            })
    }

    private showModal(title: string, message: string) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = message;

        modalRef.result.then(
            result => {
                if (result) {

                }
            }).catch((err: any) => { });
    }
    
    tooLate!: DateTime;
    tooEarly!: DateTime;

    isLoading = true;
    isRefreshing = false;
    isProcessing = false;
    changesMade = false;

    changedSlots: Change[] = [];

    readSlots: InterviewSlot[] = [];
    exclusionDateFrom?: DateTime;

    days: Day[] = [];
    calendarDate: Date = new Date(Date.now());
    calendarStartMonth = '';
    calendarStartYear = '';
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    activeWeek = 0;
    weekName = '';
}


export class calendar_item {
    startDate: DateTime;
    endDate: DateTime;
    type: number;
    name: string;

    constructor(startDate: DateTime, endDate: DateTime, type: number, name: string) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.type = type;
        this.name = name;
    }

    contains(dateTimeFrom: DateTime, dateTimeTo: DateTime): boolean {
        if (dateTimeTo < this.startDate) {
            return false;
        }    

        if (dateTimeFrom > this.endDate) {
            return false;
        }    

        const startsWithin = (dateTimeFrom >= this.startDate && dateTimeFrom < this.endDate);
        const endsWithin = (dateTimeTo >= this.startDate && dateTimeTo < this.endDate);

        // if (startsWithin) console.log(`starts within slot`);
        // if (endsWithin) console.log(`ends within slot`);

        return startsWithin || endsWithin;
    }
}