import { Component, Input, OnInit } from '@angular/core';
import { ApplicantQueueItem, InterviewDetails, InterviewerQueueResponse, InterviewerQueueType, InterviewerService, UpcomingInterviews } from './interviewer.service';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { ApplicantService } from '../applicant/applicant.service';
import { ApplicantDetailChanged, ChangedItem } from '../applicant/applicant-detail-changed';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-f2f-followup-interviews',
    templateUrl: './f2f-followup-interviews.component.html',
    styleUrls: ['./style/applicant-view-styles.scss']
})
export class F2fFollowupInterviewsComponent implements OnInit {
    @Input() interviewerId: number = 0;

    constructor(
        private router: Router,
        private appService: ApplicantService,
        private userService: PortalUserService,
        private interviewer: InterviewerService) {
    }

    ngOnInit(): void {
        this.getF2FFollowupInterviews();
        this.changeSubscription = this.appService.applicantDetailsChanged$.subscribe({
            next: (changeItem: ApplicantDetailChanged) => {
                if (changeItem.changedItem === ChangedItem.Interview || changeItem.changedItem === ChangedItem.ApplicationSubmittedToOffice) {
                    const inList = this.applicants.findIndex(a => a.applicationId === changeItem.applicationId);
                    if (inList > -1) this.getF2FFollowupInterviews();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.changeSubscription.unsubscribe();
    }

    private getF2FFollowupInterviews(): void {
        this.noData = true;
        this.isLoading = true;
        this.loadDetailsForInterviewer();
    }

    loadDetailsForInterviewer() {
        const iv = this.interviewerId === 0 ? this.userService.getInterviewerId() : this.interviewerId;
        this.interviewer
            .getApplicantByQueueForInterviewer(iv, InterviewerQueueType.F2FFollowup)
            .subscribe({
                next: (result: InterviewerQueueResponse) => {
                    this.populateData(result);
                },
                error: (error: any) => { console.error(error); this.isLoading = false; }
            });
    }

    private populateData(result: InterviewerQueueResponse) {
        if (result.details) {
            this.applicants = result.details.items;
            this.count = this.applicants.length;
            if (this.count > 0) {
                this.noData = false;
            }
            this.isLoading = false;
        }
    }

    openApplicant(a: ApplicantQueueItem): void {
        const link = `/applicant/${a.applicantId}/${a.applicationId}`;
        this.router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    useInterviewerId: number = 0;
    noData = false;

    applicants: ApplicantQueueItem[] = [];
    count = 0;

    isLoading = true;
    showInterviewer = false;
    changeSubscription!: Subscription;
}