import { MapLocation } from './map-location'
import { MapMarkerInfo } from './map-marker-info';

export interface CAMapMarker {
    id: string;
    location: MapLocation;
    label?: string;
    title: string;
    iconUrl: string;
    info?: MapMarkerInfo;
}

export class MapMarkerImpl implements CAMapMarker {
    id: string;
    location: MapLocation;
    label?: string;
    title: string;             // Used to determine which marker has been clicked on, since it's a property of the maps api marker too
    iconUrl: string;
    info?: MapMarkerInfo;

    getLatLng(): google.maps.LatLng {
        return new google.maps.LatLng(this.location.lat, this.location.lng);
    }

    constructor(id: string, location: MapLocation, label: string, title: string, iconUrl?: string) {
        this.id = id;
        this.location = location;
        this.label = label;
        this.title = title;
        // TODO(ian): If CA want their own marker icons, add here
        this.iconUrl = iconUrl || MapMarkerImpl.markerIcon(false);
    }

    static markerIcon(selected: boolean) {
        if (selected) {
            return "https://maps.google.com/mapfiles/ms/icons/purple-dot.png";
        } else {
            return "https://maps.google.com/mapfiles/ms/icons/red-dot.png";
        }
    }

    static currentLocationIcon() {
        return "https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png";// "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    }
}