import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from '@aifs-shared/user/user.service';
import { InterviewerRecord, InterviewerSearchResponse, InterviewerService } from './interviewer.service';
import { compare, compareBool, compareNumber, NgbdSortableHeader, SortEvent } from './NgbdSortableHeader';
import { Router } from '@angular/router';
import { InterviewerChangedItem, InterviewerDetailChanged } from './interviewer-detail-changed';
import { share, Subject, Subscription } from 'rxjs';
import { PortalUserPermission, PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { RoleName } from '@aifs-shared/user/user';

@Component({
    selector: 'app-interviewer-search',
    templateUrl: './interviewer-search.component.html',
    styleUrls: ['./style/interviewer-search.component.scss']
})
export class InterviewerSearchComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private title: Title,
        private interviewerService: InterviewerService,
        private userService: UserService,
        private portalUser: PortalUserService) { }

    ngOnInit(): void {
        this.title.setTitle('Camp America Management Portal');

        this.changeSubscription = this.interviewerService.interviewerDetailsChanged$.subscribe({
            next: (changeItem: InterviewerDetailChanged) => {
                // console.log(`Changed!`);
                if (changeItem.changedItem === InterviewerChangedItem.PromotionChange) {
                    this.get();
                }
            }
        });
        this.start();

        this.get();
    }

    ngOnDestroy(): void {
        //this.interviewer.interviewerDetailsChanged$.unsubscribe();
        this.changeSubscription.unsubscribe();
        this.stop();
    }

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> | undefined;

    onActiveSort({ column, direction }: SortEvent) {
        this.activeInterviewers = this.onSort({ column, direction }, this.activeInterviewers);
    }

    onInactiveSort({ column, direction }: SortEvent) {
        this.inactiveInterviewers = this.onSort({ column, direction }, this.inactiveInterviewers);
    }

    onSort({ column, direction }: SortEvent, sourceList: InterviewerRecord[] | null): InterviewerRecord[] {

        if (!sourceList || !this.headers) return [];

        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        let list: InterviewerRecord[] = [];

        // sorting countries
        if (direction === '' || column === '') {
            list = sourceList;
        } else {
            list = [...sourceList].sort((a, b) => {
                var res: number = 0;
                //if (typeof a[column] === "boolean") console.log(`Boolean: ${column}`);
                if (typeof a[column] === "boolean") {
                    // console.log(`boolean`);
                    res = compareBool(a[column] as boolean | undefined, b[column] as boolean | undefined);
                } else if (typeof a[column] === "string") {
                    console.log(`${column}: string`);
                    res = compare(a[column] as string | undefined, b[column] as string | undefined);
                } else if (typeof a[column] === "number") {
                    // console.log(`number`);
                    res = compareNumber(a[column] as number | undefined, b[column] as number | undefined);
                }
                return direction === 'asc' ? res : -res;
            });
        }
        return list;
    }

    get(): void {
        if (this.userService.isStaff()) {
            let u = this.userService.staffRole();
            if(!u) return;
            
            this.interviewerService
                .getInterviewersForStaff(u.id)
                .subscribe({
                    next: (r: InterviewerSearchResponse) => {
                        this.handleResponse(r);
                    }
                });
        } else if(this.userService.isAgent()) {
            let u = this.userService.agentRole();
            if(!u) return;

            this.interviewerService
                .getInterviewersForAgent(u.agencyId)
                .subscribe({
                    next: (r: InterviewerSearchResponse) => {
                        this.handleResponse(r);
                    }
                });
        }
    }

    handleResponse(r: InterviewerSearchResponse): void {
        this.interviewerData = r.data.filter(v => v.lastName !== '');
        this.interviewers = this.interviewerData.slice(0);

        this.activeInterviewers = this.interviewerData.filter(i => i.activeForWeb);
        this.inactiveInterviewers = this.interviewerData.filter(i => !i.activeForWeb);

                        //console.log(`active: ${this.activeInterviewers.length} inactive: ${this.inactiveInterviewers.length}`);
    }

    sortType(column: string): string {
        if (column === this.sortColumn) {
            return `${this.sortDirection}`;
        }
        return `unsorted`;
    }

    viewInterviewer(interviewerId: number) {
        if (this.portalUser.hasPermission(PortalUserPermission.ViewInterviewer)) {
            const link = `/interviewer/${interviewerId}`;
            this.router.navigate([]).then(result => { window.open(link, '_blank'); });
        }
    }

    getProfileUrl(url: string | undefined): string {
        return url ? url : '';
    }


    private onSubject = new Subject<{ key: string, value: any }>();
    public changes = this.onSubject.asObservable().pipe(share());
    private start(): void {
        // Start with a blank slate
        this.clear("interviewerChangeItem");
        window.addEventListener("storage", this.storageEventListener.bind(this));
    }

    private storageEventListener(event: StorageEvent) {
        if (event.storageArea == localStorage) {
            if (event.key && !event.newValue) {
                // Key was removed
                this.clear(event.key);
                //console.log(`removed`);
                return;
            }

            let v;
            const k = event.key;
            const nv = event.newValue;
            const ov = event.oldValue;
            let oldValue = '';
            try {
                if (event.newValue) {
                    v = JSON.parse(event.newValue);
                    //console.log(`storageEventListener: ${v}`, v);
                }

                if (ov) {
                    oldValue = JSON.parse(ov);
                }
            } catch (e) {
                v = event.newValue;
            }

            // console.log(`Key: ${k} New: ${nv}, Old: ${ov}`);

            if (!k || !nv) return;
            try {
                const newValue = JSON.parse(nv);

                // console.log(`Key: ${k} New: ${newValue}, Old: ${oldValue}`);
                if (k === "interviewerChangeItem") {
                    // console.log(`We're interested in this`);
                    const change: InterviewerDetailChanged = JSON.parse(nv);
                    // console.log(`Change: `, change);
                    if (change.changedItem === InterviewerChangedItem.PromotionChange) {
                        this.get();
                    }

                }
                this.onSubject.next({ key: k, value: v });
                this.clear(k);
            } catch (e) {
            }
        }
    }
    public clear(key: string) {
        localStorage.removeItem(key);
        this.onSubject.next({ key: key, value: null });
    }

    private stop(): void {
        window.removeEventListener("storage", this.storageEventListener.bind(this));
        this.onSubject.complete();
    }

    interviewers: InterviewerRecord[] | null = null;
    interviewerData: InterviewerRecord[] | null = null;
    sortColumn = '';
    sortDirection = '';
    changeSubscription!: Subscription;
    activeId = 1;

    activeInterviewers: InterviewerRecord[] | null = null;
    inactiveInterviewers: InterviewerRecord[] | null = null;
}
