import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InterviewDetails, InterviewerService, UpcomingInterviews } from './interviewer.service';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-upcoming-interviews',
    templateUrl: './upcoming-interviews.component.html',
    styleUrls: ['./style/upcoming-interviews.component.scss']
})
export class UpcomingInterviewsComponent implements OnInit {
    @Input() interviewerId = 0;
    @Input() showNextOverhead = true;
    @Input() showThisWeek = true;
    @Input() showTime = false;
    @Input() showTable = true;
    @Input() title?: string;
    @Input() compact = false;
    @Input() filter = "";
    @Input() filterCountry = '';

    constructor(
        private router: Router,
        private interviewer: InterviewerService,
        private userService: PortalUserService) {
        // console.log(`InterviewerId: ${this.interviewerId}`);
        if (this.interviewerId == 0) {
            this.noInterviews = "There are no interviews scheduled";
        }
    }

    ngOnInit(): void {
        const iv = this.interviewerId === 0 ? this.userService.getInterviewerId() : this.interviewerId;
        if (iv <= 0 ) {
            // We want to read this for ALL interviewers belonging to this staff/agency
            if (this.userService.hasStaffRole()) this.loadDetailsForStaff();
            else if (this.userService.hasAgentRole()) this.loadDetailsForAgent();
        } else {
            this.loadDetailsForInterviewer(iv);
        }
    }


    loadDetailsForInterviewer(interviewerId: number) {
        // console.log(`Loading interviews for ${interviewerId}`);
        this.isLoading = true;
        this.interviewer.getUpcomingInterviewsForInterviewer(interviewerId)
            .subscribe(
                {
                    next: (result: UpcomingInterviews) => {
                        this.populateData(result);
                    },
                    error: (error) => {
                        this.receivedError(error);
                    }
                });
    }

    loadDetailsForAgent() {
        this.isLoading = true;
        const agentId = this.userService.getAgentId();
        this.showInterviewer = true;
        this.interviewer.getUpcomingInterviewsForAgent(agentId)
            .subscribe(
                {
                    next: (result: UpcomingInterviews) => {
                        this.populateData(result);
                    },
                    error: (error) => {
                        this.receivedError(error);
                    }
                });
    }

    loadDetailsForStaff() {
        this.isLoading = true;
        const staffId = this.userService.getStaffId();
        this.showInterviewer = true;
        this.interviewer.getUpcomingInterviewsForStaff(staffId)
            .subscribe(
                {
                    next: (result: UpcomingInterviews) => {
                        this.populateData(result);
                    },
                    error: (error) => {
                        this.receivedError(error);
                    }
                });
    }

    private populateData(result: UpcomingInterviews) {
        if (result) {
            if (result.next) {
                this.hasNext = true;
                this.next = result.next;
                // console.log(`I have a next interview: ${JSON.stringify(result.next, null, 2)}`);
            } else {
                this.hasNext = false;
                // console.log(`I don't have a next interview`);
            }

            var up: InterviewDetails[] = [];        

            if (result.thisWeek) {
                // console.log(`I have thisWeek interviews: ${JSON.stringify(result.thisWeek, null, 2)}, filter is ${this.filter}`);
                if (this.filter !== "") {
                    const confirmValue = this.filter === "C" ? true : false;
                    if(result.next) this.addDisplayNextInterview(result.next, confirmValue);

                    result.thisWeek.forEach(e => {
                        if (e.isConfirmed == confirmValue) {
                            this.thisWeek.push(e);
                        }
                    });
                } else {
                    this.thisWeek = result.thisWeek.slice();
                    if(result.next) this.addDisplayNextInterview(result.next);
                }
            }         
            
            if (up.length > 0) {
                // console.log(`I should be pushing next in`);
                this.thisWeek = up.concat(...this.thisWeek);
            }

            this.thisWeek.forEach(w => {
                const canShow = this.canShowTime(w);
                w.displayDate = canShow ? w.date : '';
                w.displayTime = canShow ? w.hour : 'Request';
            });

            this.count = this.thisWeek.length;
            this.hasForthcoming = this.thisWeek.length > 0;
        } else {
            this.hasNext = false;
            this.hasForthcoming = false;
        }
        this.isLoading = false;
    }

    private addDisplayNextInterview(nextInterview: InterviewDetails, confirmValue : boolean | null = null){
        if (confirmValue == null || nextInterview.isConfirmed == confirmValue) { 
            this.thisWeek.unshift(nextInterview);
        }
    }

    private receivedError(error: any) {
        console.error(error);
        this.isLoading = false;
        this.hasNext = false;
        this.hasForthcoming = false;        
    }

    openApplicant(next: InterviewDetails) {
        //console.log(`Open ${next.applicantId}/${next.applicationId}`);
        const link = `/applicant/${next.applicantId}/${next.applicationId}`;
        // console.log(`Showing applicant ${applicantId}`);
        this.router.navigate([]).then((result:any) => { window.open(link, '_blank'); });        
    }

    getTime(i: InterviewDetails): string {
        const d = DateTime.fromISO(i.date);
        return `${d.monthShort} ${d.day} ${i.hour}`;
    }

    canShowTime(u: InterviewDetails): boolean {
        return this.showTime && (u.status !== 'REQ' || u.hour !== "00:00");
    }

    isLoading = false;
    hasNext = true;
    hasForthcoming = true;
    count = 0;
    next?: InterviewDetails;
    thisWeek: InterviewDetails[] = [];
    noInterviews = "You have no interviews scheduled.";
    showInterviewer = false;
}