import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { InterviewerService } from '../interviewer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationDetails } from '../location.details';

@Component({
    selector: 'app-interview-loc-list',
    templateUrl: './interview-location-list.component.html',
    styleUrls: ['./style/interview-locations.scss']
})
export class InterviewLocationListComponent implements OnInit, OnChanges, OnDestroy {
    @Input() locations: LocationDetails[] = [];
    @Input() interviewerId: number = -1;
    @Output() locationSelected: EventEmitter<LocationDetails> = new EventEmitter();

    constructor(
        private interviewerService: InterviewerService,
        private modalService: NgbModal,
    ) {

    }

    ngOnInit(): void {
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["locations"]) {
            this.updateActiveLocations();
        }
    }

    ngOnDestroy(): void {
        
    }    

    onLocationSelected(location: LocationDetails): void {
        this.locationSelected.emit(location);
    }

    onLocationDetailsChanged(location: LocationDetails): void {
        this.updateActiveLocations();
    }

    private updateActiveLocations(): void {
        this.activeCount = this.locations.filter(l => l.isActive).length;
        // console.log(`Got ${this.activeCount} active locations`);
    }

    activeCount = 0;
}