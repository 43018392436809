import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgencyInterviewerDetailsResponse, AgentInterviewerDetails, InterviewerService } from './interviewer.service';
import { UserFormService } from '@aifs-shared/forms/user-form.service';
import { PortalUserPermission, PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { RoleName } from '@aifs-shared/user/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InterviewerProfileBioModalComponent } from './interviewer-profile-bio-modal.component';
import { ConfirmationModal } from '@aifs-shared/modals';
import { InterviewerChangedItem, InterviewerDetailChanged } from './interviewer-detail-changed';
import { Subscription } from 'rxjs';
import { InterviewerAvailabilityModalComponent } from './interviewer-availability-modal.component';

@Component({
    selector: 'app-interviewer-profile',
    templateUrl: './interviewer-profile.component.html',
    styleUrls: ['./style/interviewer-profile.component.scss']
})
export class InterviewerProfileComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private title: Title,
        private interviewerService: InterviewerService,
        private userService: PortalUserService,
        private modalService: NgbModal) {
    }

    ngOnInit(): void {
        let interviewerId = 0;

        if (this.userService.isInRoleOrAbove(RoleName.Agent) && this.route.snapshot.params['id']) {
            interviewerId = +this.route.snapshot.params['id'];
        }

        if (interviewerId === 0) {
            interviewerId = this.userService.getInterviewerId();
        }

        this.userId = this.userService.getUser().userId;
        sessionStorage.setItem('returnUrl', '/interviewer/profile')

        this.getInterviewerDetails(interviewerId);

        this.changeSubscription = this.interviewerService.interviewerDetailsChanged$
            .subscribe({
                next: (changeItem: InterviewerDetailChanged) => {
                    // console.log(`Changed!`);
                    if (interviewerId === changeItem.interviewerId) {
                        this.interviewerService
                            .getInterviewerDetails(changeItem.interviewerId)
                            .subscribe({
                                next: (result: AgencyInterviewerDetailsResponse) => {
                                    this.interviewer = result?.details;
                                },
                                error: (error) => {
                                    console.error(error);
                                }
                            });
                    }
                }
            })
    }

    ngOnDestroy(): void {
        this.changeSubscription.unsubscribe();
    }

    getInterviewerDetails(interviewerId: number): void {
        this.isLoading = true;
        this.interviewerService
            .getInterviewerDetails(interviewerId)
            .subscribe({
                next: (result: AgencyInterviewerDetailsResponse) => {
                    // console.log(`${JSON.stringify(result, null, 2)}`);
                    this.interviewer = result.details;
                    this.isLoading = false;
                    this.selectedInterviewerId = this.interviewer?.id;
                    this.title.setTitle(`${this.interviewer!.firstName} ${this.interviewer!.lastName} (${this.selectedInterviewerId})`);
                    this.updateButtonState();
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            })
    }

    showAvailability(): void {
        const modalRef = this.modalService.open(InterviewerAvailabilityModalComponent, { size: 'lg', windowClass: 'modal-availability', scrollable: true });
        modalRef.componentInstance.interviewerId = this.interviewer?.id;
        modalRef.result.then().catch((err: any) => { });
    }
    
    viewInterviewerProfile(): void {
        // console.log(`Show interviewer bio + notes`);

        const modalRef = this.modalService.open(InterviewerProfileBioModalComponent, { size: 'lg', windowClass: 'modal', scrollable: true });
        modalRef.componentInstance.interviewerId = this.interviewer?.id;
        modalRef.componentInstance.interviewer = this.interviewer;
        modalRef.componentInstance.title = `${this.interviewer?.firstName} ${this.interviewer?.lastName}`;
        modalRef.result.then().catch((err: any) => { });
    }

    toggleActiveForWeb(): void {
        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = this.activeButtonText;
        modalRef.componentInstance.body = `Are you sure you want to change the active status for ${this.interviewer?.firstName} ${this.interviewer?.lastName}?`;
        modalRef.result.then(result => {
            if (result) {
                const newActive = !this.interviewer!.activeForWeb;
                this.interviewerService
                    .setActiveState(this.userService.getUser().userId, this.interviewer!.id, newActive)
                    .subscribe({
                        next: (result: boolean) => {
                            this.interviewer!.activeForWeb = newActive;
                            this.updateButtonState();
                            this.interviewerService.notifyInterviewerDetailChanged(new InterviewerDetailChanged(InterviewerChangedItem.ActiveForWebState, this.interviewer!.id));
                        },
                        error: (error: any) => {
                            console.error(error);
                        }
                    });
            }
        }).catch((err: any) => { });

    }

    updateButtonState(): void {
        this.activeButtonDisabled = !this.userService.hasPermission(PortalUserPermission.EditInterviewer);
        this.activeButtonText = this.interviewer!.activeForWeb ? "Make inactive" : "Make active";
    }

    interviewer?: AgentInterviewerDetails;
    userId = -1;
    isLoading = true;
    selectedInterviewerId?: number;
    activeButtonText = "Make inactive";
    activeButtonDisabled = true;
    changeSubscription!: Subscription;
}
