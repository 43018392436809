import { BaseService } from '@aifs-shared/common/base-service';
import { User } from '@aifs-shared/user/user';
import { UserService } from '@aifs-shared/user/user.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { InterviewerChangedItem, InterviewerDetailChanged } from '../interviewer/interviewer-detail-changed';
import { InterviewerService } from '../interviewer/interviewer.service';

@Injectable({
  providedIn: 'root'
})
export class PromoteInterviewerService extends BaseService {

    constructor(
        private http: HttpClient,
        private interviewerService: InterviewerService,
        private userService: UserService) {
        super();
    }

    setPromotion(id: number, isPromoted: boolean) {
        this.http
            .post(environment.ServiceUrl_Agency_InterviewerPromotion(id),
                { isPromoted: isPromoted })
            .subscribe({
                next: (result) => {
                    this.interviewerService.notifyInterviewerDetailChanged(new InterviewerDetailChanged(InterviewerChangedItem.PromotionChange, id));
                },
                error: (error) => {
                    console.error(error);
                }
            });
    }

    currentUser?: User | null;
}
