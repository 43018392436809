import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InterviewerService, InterviewSlot, InterviewSlotResponse } from './interviewer.service';
import { Day, SelectedTimeSlot, TimeSlot } from '@aifs-shared/calendar/day';
import { CalendarItem } from '@aifs-shared/calendar/calendar-item';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { InterviewerChangedItem, InterviewerDetailChanged } from './interviewer-detail-changed';

@Component({
    selector: 'app-interviewer-availability',
    templateUrl: './interviewer-availability.component.html', 
    styleUrls: ['./style/interviewer-availability.component.scss']
})
export class InterviewerAvailabilityComponent implements OnInit, OnDestroy {
    @Input() interviewerId: number = 0;
    @Input() displaySummary = false;

    @Output() itemSelected = new EventEmitter<SelectedTimeSlot>();
    @Output() changesMade = new EventEmitter<Day>();

    constructor(
        private interviewer: InterviewerService) {
        this.subscription = this.interviewer.interviewerDetailsChanged$.subscribe({
            next: (change: InterviewerDetailChanged) => {
                if (change.interviewerId === this.interviewerId && change.changedItem === InterviewerChangedItem.Availability) {
                    // console.log(`Change in availability for interviewer ${change.interviewerId}`);
                    this.loadDetails(change.interviewerId);
                }
            }
        })
    }

    ngOnInit(): void {
        const iv = this.interviewerId
        this.loadDetails(iv);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    loadDetails(interviewerId: number): void {
        this.interviewer
            .getInterviewSlotsForInterviewer(interviewerId)
            .subscribe({
                next: (result: InterviewSlotResponse) => {
                    // console.log(`Got: ${JSON.stringify(result, null, 2)}`);
                    const slots = result.slots;
                    if (result.exclusionFrom) {
                        this.exclusionDate = DateTime.fromISO(result.exclusionFrom);
                        //console.log(`Got exclusion date: ${this.exclusionDate}`);
                    }

                    if (slots.length == 0) {
                        //console.log(`Didn't get any slots`)
                    } else {
                        this.generateTimeSlotsAndDays(slots);
                    }

                    this.isLoading = false;
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            });
    }

    timeSlotSelected(selection: SelectedTimeSlot) {
        this.itemSelected.emit(selection);
    }

    userSelectedDate(selection: CalendarItem) {
        //console.log(`date selected: ${selection.date}`);
    }

    private generateTimeSlotsAndDays(slots: InterviewSlot[]) {
        this.readSlots = slots;
        this.availableCount = 0;
        this.totalCount = slots.length;
        var day = 0;
        var available = false;
        while (day < 8) {
            day++;
            const daySlots = slots.filter(s => s.dayOfWeek === day);
            if (daySlots && daySlots.length > 0) {
                const d = new Day(daySlots[0].day.substring(0, 3), true, day);
                daySlots.forEach(ds => {
                    if (!available && ds.isAvailable) available = true;
                    if (ds.isAvailable) this.availableCount++;
                    d.slots.push(new TimeSlot(ds.availabilitySlotId, ds.startTime, ds.endTime, ds.isAvailable));
                });
                this.days.push(d);
            }
        }
        if (this.totalCount > 0) this.percentAvailable = Math.ceil((this.availableCount / this.totalCount) * 100);
        this.noSlots = !available;
    }

    subscription?: Subscription;

    isLoading = true;

    noSlots = true;
    readSlots: InterviewSlot[] = [];
    days: Day[] = [];
    exclusionDate?: DateTime;
    availableCount = 0;
    totalCount = 0;
    percentAvailable = 0;
}

