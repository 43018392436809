<h2>Calendar</h2>
<div class="calendar" *ngIf="!isLoading; else still_loading">
    <div class="info-panel">
        <button type="button" class="btn btn-primary btn-wide" [disabled]="!changesMade || isProcessing"
            (click)="saveChanges()">Save Changes
            <img *ngIf="isProcessing" src="img/white-loader.gif"></button>

        <button class="button btn-wide btn-primary" (click)="changeAvailability()">Edit my availability</button>
        <app-month [month]="monthCalculator" (dateChanged)="newWeekSelected($event)" (monthChanged)="newMonthSelected($event)"></app-month>
    </div>
    <div class="calendar-container">
        <div class="">
            <div class="controls">
                <i (click)="changeWeek(-1)" class="fa-solid fa-chevron-left"></i>
                <h2 class="week">{{weekName}}</h2>
                <i (click)="changeWeek(1)" class="fa-solid fa-chevron-right"></i>
            </div>
            <app-calendar [class.refresh]="isRefreshing" [days]="days" (itemSelected)="calendarItemSelected($event)"></app-calendar>
        </div>
    </div>    
    <div class="calendar-selection">
        <app-upcoming-interviews [showNextOverhead]="true" [showThisWeek]="true"></app-upcoming-interviews>
    </div>
</div>
<ng-template #still_loading>
    <div class="load_container">
        <img src="img/stopwatch.svg" class="bl">
    </div>
</ng-template>
