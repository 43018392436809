
<h2>Calendar</h2>
<div class="calendar-container">
    <div class="info-panel">
        <p><a routerLink="/interviewer/calendar"><i class="fas fa-angle-left"></i> Go Back</a></p>
        <button type="button" class="btn btn-primary btn-wide" [disabled]="!changesMade || isProcessing" (click)="saveChanges()">
            Save Changes <img *ngIf="isProcessing" src="img/white-loader.gif">
        </button>
        <p class="alert alert-warning" *ngIf="changesMade && changedInterviewerTimezone">
            You have updated your timezone. Please <b>check your availability</b> for the new timezone before saving.
        </p>
        <p>Set each interview slot as available or unavailable. Any slot marked as available will be shown to applicants for them to book.</p>  
    </div>
    <div class="calendar">
        <h2>Set your general availability for interviews</h2>
        <app-time-slot [days]="days" (itemSelected)="timeSlotSelected($event)"></app-time-slot>
    </div>
    <div class="calendar-selection">
        <app-exclusion [date]="exclusionDate" (exclusionDateSet)="changeExclusion($event)"></app-exclusion>
        <app-timezone [timezone]="interviewerTimezone" (timezoneSet)="changeTimezone($event)"></app-timezone>
        <div class="calendar-selection-slot" *ngIf="selectedDay && selectedTimeSlot">
            <h3>{{selectedDay.name}}</h3>
            <h4>{{selectedTimeSlot.name}}</h4>
            <!-- <p *ngIf="selectedTimeSlot.isAvailable">Slot is available for bookings.</p>
            <p *ngIf="!selectedTimeSlot.isAvailable">Slot is unavailable for bookings.</p> -->
        </div>
    </div>
</div>    