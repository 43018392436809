import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';

import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { InterviewerService, OnlineInterviewAvailability } from './interviewer.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-summary-calendar',
    templateUrl: './summary-calendar.component.html',
    styleUrls: ['./style/summary-calendar.component.scss']
})
export class SummaryCalendarComponent implements OnInit {
    @Input() filterCountry: string = '';
    @Output() selectedSlot: EventEmitter<Slot> = new EventEmitter<Slot>();

    constructor(
        private router: Router,
        private title: Title,
        private interviewer: InterviewerService,
        private userService: PortalUserService) {
    }

    ngOnInit(): void {
        this.title.setTitle('Camp America Management Portal');
    

        this.getDates()
            .subscribe({
                next: (result:any) => {
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filterCountry']) {
            this.isLoading = true;
            this.getDates();
            if (this.lastClickedSlot) {
                this.selectedSlot.emit(this.lastClickedSlot);
            }
            this.isLoading = false;
        }
    }

    getDates(): Observable<boolean> {
        const s = new Subject<boolean>();
        this.inError = false;

        let agentId = 0;
        let staffId = 0;

        // Staff role should take precedence
        if (this.userService.hasStaffRole()) {
            staffId = this.userService.getStaffId();
        } else if (this.userService.hasAgentRole()) {
            agentId = this.userService.getAgentId();
        } else {
            console.error(`User does not have a staff or agent role`);
        }

        this.interviewer
            .onlineInterviewAvailability(this.filterCountry)
            .subscribe(
                {
                    next: (data: OnlineInterviewAvailability) => {
                        // console.log(`got data: ${JSON.stringify(data, null, 2)}`);
                        this.displayReturnedDates(data);
                        s.next(true);
                    },
                    error: (error: any) => {
                        this.displayReturnedError(error);
                        s.next(true);
                    }
                }
            );

        return s;
    }

    displayReturnedDates(data: OnlineInterviewAvailability): void {
        this.availability = data;
        this.displayDates();
        this.isLoading = false;
    }

    displayReturnedError(error: any): void {
        let msg = error;
        if (error instanceof HttpErrorResponse) {
            // you could extract more info about the error if you want, e.g.:
            msg = `${error.statusText} (${error.status})`;
            // errMsg = ...
        }
        console.error(error);
        //this.showAlert('Select Interview', `An error occured trying to read the interviewer calendar. If this error continues, please contact us!`, msg);

        this.inError = true;
        this.errorText = msg;
        this.isLoading = false;
    }

    displayDates(): void {
        const a = this.availability;
        if (a) {
            this.days = [];
            for (var i = 0; i < 7; i++) {
                const day = a.calendar.days[this.currentSlot + i];
                const thisSlot = DateTime.fromISO(day.isoDate);

                const weekDay = thisSlot.weekdayShort;
                const date = `${thisSlot.day} ${thisSlot.monthShort}`;

                var d = new Day(thisSlot, weekDay!, date);

                for (var j = 0; j < this.validSlots.length; j++) {
                    const ds = day.slots.filter( sl => sl.startTime === this.validSlots[j]);
                    if (ds && ds.length > 0) {
                        ds.forEach(s => {
                            d.slots.push(new Slot(d, s.startTime, s.count, a.totalInterviewers));
                        });
                    } else {
                        d.slots.push(new Slot(d, this.validSlots[j], 0, a.totalInterviewers));
                    }
                }

                this.days.push(d);
            }

            //console.log(`Slots: `, this.days);

            this.canViewNext = this.currentSlot + 7 < a.calendar.days.length; // this.startFrom < this.latestDate;
            this.canViewPrevious = this.currentSlot > 0;// this.startFrom > this.earliestDate;
        }
    }

    
    selectSlot(s: Slot): void {
        this.lastClickedSlot = s;
        this.selectedSlot.emit(s);
    }

    previousDates(): void {
        if (!this.canViewPrevious) return;

        // const d = this.startFrom.minus({ days: 3 });
        // this.startFrom = d;
        this.currentSlot -= 1;
        this.displayDates();
    }

    nextDates(): void {
        if (!this.canViewNext) return;

        // const d = this.startFrom.plus({ days: 3 });
        // this.startFrom = d;
        this.currentSlot += 1;
        this.displayDates();
    }

    slotStyle(s: Slot) {
        let pct = s.count;
        if (pct > 5) pct = 5;
        return  `op0${pct}`;
    }

    slotTitle(s: Slot) {
        const count = s.count;
        if (count == 0) return 'No interviewers available';
        if (count == 1) return '1 interviewer available';
        return `${s.count} interviewers available`;
    }

    isLoading = true;
    isProcessing = false;

    noDetailsReturned = false;
    days: Day[] = [];

    onlineInterviewStep = 1;

    startFrom?: DateTime;
    earliestDate?: DateTime;
    latestDate?: DateTime;

    canViewPrevious = false;
    canViewNext = true;
    inError = false;
    errorText?: string;
    availability?: OnlineInterviewAvailability;
    currentSlot = 0;
    lastClickedSlot?: Slot;

    
    validSlots = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'];
    ordinals = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
}

export class Day {
    systemDate: DateTime;
    dayOfWeek: string;
    date: string;
    hasNoSlots: boolean = false;
    slots: Slot[] = [];
    constructor(systemDate: DateTime, dayOfWeek: string, date: string) {
        this.systemDate = systemDate;
        this.dayOfWeek = dayOfWeek;
        this.date = date;
    }
}

export class Slot {
    day: Day;
    startTime: string;
    count: number;
    percent: number;

    constructor(day: Day, time: string, count: number, totalInterviewerCount: number) {
        this.day = day;
        this.startTime = time;
        this.count = count;
        if (totalInterviewerCount > 0) {
            this.percent = count / totalInterviewerCount;
        } else {
            this.percent = 0;
        }
    }
}