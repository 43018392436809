import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicantQueueItem, InterviewerService } from './interviewer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './completed-interviews-modal.component.html',
    styleUrls: ['./style/applicant-view-styles.scss', '../shared/modals/style/modal-styles-generic.scss']
})
export class CompletedInterviewsModalComponent implements OnInit {
    @Input() applicants: ApplicantQueueItem[] = [];

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private userService: PortalUserService,
        private interviewer: InterviewerService) {
    }

    ngOnInit(): void {
    }

    openApplicant(a: ApplicantQueueItem): void {
        const link = `/applicant/${a.applicantId}/${a.applicationId}`;
        // console.log(`Showing applicant ${applicantId}`);
        this.router.navigate([]).then(result => { window.open(link, '_blank'); }); 
    }

    closeModal() {
        this.modalService.dismissAll();
    }
}