import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicantRoutingModule } from '../applicant/applicant-routing.module';
import { PipesModule } from '@aifs-shared/pipe/pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PromoteInterviewerService } from './promote-interviewer.service';
import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PromoteButtonComponent } from './promote-button.component';

@NgModule({
    declarations: [
        PromoteButtonComponent
    ],
    imports: [
        CommonModule,
        ApplicantRoutingModule,
        PipesModule,
        NgbModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        PromoteInterviewerService
    ],
    exports: [
        PromoteButtonComponent
    ]
})
export class PromoteInterviewerModule { }
