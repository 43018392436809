import { Component, Input, OnInit } from '@angular/core';
import { InterviewDetails, InterviewerService } from './interviewer.service';
import { Router } from '@angular/router';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './upcoming-interviews-modal.component.html',
    styleUrls: ['./style/upcoming-interviews.component.scss', '../shared/modals/style/modal-styles-generic.scss']
})
export class UpcomingInterviewsModalComponent {
    @Input() thisWeek: InterviewDetails[] = [];
    @Input() showTime = false;
    @Input() compact = false;
    @Input() filter = "";
    @Input() showInterviewer = false;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private interviewerService: InterviewerService,
        private userService: PortalUserService) {
    }

    closeModal() {
        this.modalService.dismissAll();
    }

}