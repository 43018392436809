import { UserService } from '@aifs-shared/user/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { PromoteInterviewerService } from './promote-interviewer.service';
import { AgentInterviewerDetails } from '../interviewer/interviewer.service';

@Component({
    selector: 'app-promote-button',
    templateUrl: './promote-button.component.html',
    styleUrls: ['./style/promote-button.component.scss'],
    animations: [
        trigger('addRemove', [
            state('add', style({
                opacity: 0.5,
                height: '2.5rem'
            })),
            state('remove', style({
                opacity: 1,
                height: '5rem'
            })),
            transition('add => remove', [
                animate('0.75s')
            ]),
            transition('remove => add', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class PromoteButtonComponent implements OnInit {

    @Input() interviewer!: AgentInterviewerDetails;

    constructor(
        private userService: UserService,
        private promotions: PromoteInterviewerService) {
    }

    ngOnInit(): void {
        this.isPromoted = this.interviewer.isPromoted;
        this.updateDetails();
    }

    promote() {
        if (this.isPromoted) {
            this.removePromotion();
        } else {
            this.savePromotion();
        }
    }

    private savePromotion() {
        this.isPromoted = true;        
        this.promotions.setPromotion(this.interviewer!.id, true);
        this.updateDetails();
    }

    private removePromotion() {
        this.isPromoted = false;
        this.promotions.setPromotion(this.interviewer!.id, false);
        this.updateDetails();
    }
    
    private updateDetails() {
        // console.log(`Is Promoted: ${this.interviewer!.isPromoted}`);
        this.interviewer.isPromoted = this.isPromoted;
        this.buttonText = !this.isPromoted ? `Promote` : 'Remove Promotion';
    }

    buttonText = `Promote`;
    isPromoted = false;
}
